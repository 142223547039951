import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { sendMailToOssNoLogin } from '@/services/emailService';
import { RESET_CALCULATORS, UPDATE_BASKET } from '@/store/modules/calculatorContext';
import { RESET_SELECTABLE } from '@/store/modules/selectableContext';
import store from '@/store/store';
import { cloneDeep } from 'lodash';
import { ModalSubmitToggle } from '../services/AlertService';
import { isOpenHours, setOpenTxtOnReceipt } from '../services/AvailabilityService';
import { getDiscount, handleSpecialCarDiscounts } from '../services/CalculationService';
import { addStepToHistory } from '../services/NavigationService';
import {
	buildHeaderHighlights,
	addProp,
	getBrandProp,
	buildCreditCardInfo,
	sendErrorMailToTeam,
	sendMailToTeam,
	dogFields,
	childrenFields,
} from '../services/OrderService';
import {
	getCalculatorNameUi,
	getCoverageNames,
	resetCampaignStorage,
	stripProductIndex,
} from '../services/ProductService';
import { capitalizeFirstLetter } from '../services/UiStepService';
import { Formatter } from '../services/ValidationService';
import { Model } from './Model';
import { sendToSalesForce } from '@/services/SalesForceLeadService';

export default class MapOrderDescription {
	private uiComp: any;
	public checkoutProducts: Array<{
		name: string;
		id: string;
		price: string;
		brand: string;
		category: string;
		quantity: number;
	}> = [];

	public constructor(uiComp: any) {
		this.uiComp = uiComp;
	}

	public async orderByEmail() {
		const model = this.uiComp.model;
		const cms = this.uiComp.cms;
		try {
			// get commonModel for calculators
			const commonPersonInfo = await store.getters.getCalculatorCommonModel.personInfo;
			if (!commonPersonInfo.address && cms.customerAddressRequired && model.personInfo.address) {
				model.personInfo = Object.assign({}, commonPersonInfo, model.personInfo);
			} else {
				Object.assign(model.personInfo, commonPersonInfo);
			}

			const productNames = await store.getters.getSelectableGroup('basket');
			let price = 0;
			let missingCalculations = false;
			const monthly = model.choosePackage.monthYear === 'M';
			productNames.forEach((productName) => {
				const calculator = store.getters.getCalculator(productName);
				if (calculator && calculator.model?.readyForBasket) {
					if (monthly) {
						price += calculator.model.choosePackage.selectedPackage.totalPrice;
					} else {
						price += calculator.model.choosePackage.selectedPackage.yearlyPriceTotal;
					}
				} else {
					missingCalculations = true;
				}
			});

			const res = buildHeaderHighlights(model, true);

			if (missingCalculations) {
				res.desc += `<li><h2><i>Én eller flere beregninger mangler</i></h2></li>`;
				res.tracking += '/missingCalculations';
			}
			res.desc = '<ul>' + res.desc + '</ul>';

			await this.submitOrder(res.desc, res.tracking, price, missingCalculations);

			await store.dispatch(RESET_SELECTABLE, 'basket');
			model.currentCardName = 'receipt';
		} catch (e) {
			console.error(e);
			store.state.showSpinner = false;
			ModalSubmitToggle(cms, 'basket submitOrder');
			sendErrorMailToTeam(e, model);
		}
	}

	public async submitOrder(desc: string, tracking: string, totalPrice: number, missingCalculations: boolean) {
		const model = this.uiComp.model;
		const cms = this.uiComp.cms;

		desc = `<h2>Kunde har bestilt samlet tilbud</h2>${desc}`;

		desc += getBrandProp();
		if (model.campaign.valid) {
			const id = model.campaign.ID ? model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
			if (model.campaign.uniqueKey) {
				desc += addProp(`${id} unik ID`, model.campaign.uniqueKey);
			}
			if (model.campaign.description) {
				desc += addProp(`Kampagne beskrivelse`, model.campaign.description);
			}
			if (model.campaign.groupId > 0) {
				desc += addProp(`Kampagne Gruppe kode`, model.campaign.groupId);
			}
			if (model.campaign.discount > 0) {
				desc += addProp(`Kampagne rabat inkluderet i pris`, model.campaign.discountDisplay);
			}
			if (model.campaign.tiaDiscount > 0) {
				desc += addProp(
					`Kampagne rabat fra TIA inkluderet i pris`,
					Formatter.format(100 * model.campaign.tiaDiscount) + ' %'
				);
			}
			if (model.campaign.splash) {
				desc += addProp(`Kampagne specifik`, model.campaign.splash);
			}
		}

		const params: Map<string, string> = await this.buildDescriptionForEmail(desc, totalPrice);

		params.set('subject', `Kunde samlet tilbud`);
		params.set('name', model.contact_information.name);
		params.set('cprNo', model.contact_information.cpr);
		params.set('email', model.contact_information.email);
		params.set('phoneNo', model.contact_information.phone);

		const basket = store.getters
			.getSelectableGroup('basket')
			.map((name) => capitalizeFirstLetter(name.replace('0', '')));

		let products: string = basket.join(', ');
		const lastInx = products.lastIndexOf(',');
		products = products.slice(0, lastInx) + ' og' + products.slice(lastInx + 1);
		params.set('products', products);

		const monthly = model.choosePackage.monthYear === 'M';
		const monthlyYearlyDisplay = monthly ? ' kr. Månedligt' : ' kr. Årligt';

		const trackOrderProducts = [];
		const productNames: Array<string> = [];
		let totalYearlyPrice = 0;

		const productsPrice = store.getters.getSelectableGroup('basket');
		productsPrice.forEach((productName) => {
			const calculator = store.getters.getCalculator(productName);
			if (calculator?.cms) {
				productNames.push(calculator.cms.shortTitle);
			} else {
				const pName = stripProductIndex(productName).replace('forsikring', '');
				productNames.push(capitalizeFirstLetter(pName));
			}
			if (calculator?.model?.readyForBasket) {
				// product calculated
				const selectedPackage = calculator.model.choosePackage.selectedPackage;

				let price = '';
				if (monthly) {
					price = Formatter.format(selectedPackage.totalPrice) + monthlyYearlyDisplay;
				} else {
					price = Formatter.format(selectedPackage.yearlyPriceTotal) + monthlyYearlyDisplay;
				}
				totalYearlyPrice += selectedPackage.yearlyPriceTotal;
				params.set(
					productName.replace('ø', 'oe'),
					capitalizeFirstLetter(productName.replace('0', '')) + ', ' + price
				);
				trackOrderProducts.push(stripProductIndex(productName));
			} else {
				params.set(productName.replace('ø', 'oe'), capitalizeFirstLetter(productName.replace('0', '')));
			}
		});

		if (!missingCalculations) {
			params.set('isOpenMultiple', isOpenHours(cms.open) + '');
		}
		params.set('uncalculatedProducts', missingCalculations + '');
		params.set('price', totalPrice + '');
		params.set('monthlyPayment', monthly ? 'true' : 'false');

		if (totalPrice > 1) {
			params.set('priceFormatted', `${Formatter.format(totalPrice)}${monthlyYearlyDisplay}`);
			if (missingCalculations) {
				params.set('uncalculatedProductsWithPrice', 'true');
			}
		} else {
			params.set('priceFormatted', '0');
		}
		params.set('multipleInsurances', 'true');

		const startTime = new Date().getTime();

		let sendToSalesForceDone = false;
		if (cms.sendProposalToSalesForce) {
			try {
				const productTitles = productNames.join(', ');
				await sendToSalesForce(totalYearlyPrice, productTitles, params.get('description'), this.uiComp);
				sendToSalesForceDone = true;
			} catch (e) {
				console.error(e);
				sendErrorMailToTeam(e, model);
			}
		}

		// TODO KLK - always send email - used for customer receipt
		await sendMailToOssNoLogin(params);

		// if (!sendToSalesForceDone || cms.sendProposalToEmail) {
		//     // always try email if offer not posted to salesForce
		//     await sendMailToOssNoLogin(params);
		// }

		if (cms.mailConfirmationToTeam) {
			await sendMailToTeam(model);
		}

		const unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === RESET_CALCULATORS) {
				setTimeout(() => {
					this.uiComp.checkout = true;
					const model = cloneDeep(Model.model);
					store.dispatch(UPDATE_BASKET, { cms: cms, model });
					unsubscribe();
				}, 2500);
			}
		});

		const waitForEmail = cms.waitForEmail;
		if (waitForEmail && waitForEmail > 0) {
			await new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve('Done waiting');
				}, cms.waitForEmail);
			});
		}

		// this.checkoutBasket();
		resetCampaignStorage(model);

		const mailDuration = new Date().getTime() - startTime;
		const fakeTimeout = cms.animationTimeoutBuy - mailDuration;
		setTimeout(
			() => {
				setOpenTxtOnReceipt(true, missingCalculations, this.uiComp);
				model.showReceipt = true;
				store.state.showSpinner = false;
				window.scrollTo(0, 0);
				model.currentCardName = 'receipt';
				setTimeout(() => {
					this.uiComp.scrollToReceipt();
				}, cms.animationTimeout);
				addStepToHistory(true, this.uiComp);
			},
			fakeTimeout < 0 ? 100 : fakeTimeout
		);

		// if stuff hangs
		setTimeout(() => {
			if (store.state.showSpinner) {
				store.state.showSpinner = false;
			}
		}, cms.calculationSpinnerTimeout);
	}

	private async buildDescriptionForEmail(desc: string, totalPrice: number) {
		const model = this.uiComp.model;
		const cms = this.uiComp.cms;

		desc += '<h2>Person</h2>';
		const now: Date = new Date();
		desc += addProp(
			'Email sendt',
			`${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${
				now.getMonth() + 1
			}-${now.getFullYear()}`
		);
		desc += addProp('Navn', model.contact_information.name);

		if (model.personInfo?.address) {
			// address on basket model
			desc += addProp('Adresse', model.personInfo.address);
		} else if (model.personInfo?.zipCode) {
			desc += addProp('Postnr./by', `${model.personInfo.zipCode} ${model.personInfo.zipName}`);
		}
		if (model.personInfo.customerAge) {
			desc += addProp('Alder', model.personInfo.customerAge);
		}
		if (model.contact_information.customerNo) {
			desc += addProp('KundeNr', model.contact_information.customerNo);
		} else {
			desc += addProp('Cpr', model.contact_information.cpr);
		}
		if (model.personInfo.familyMemberCount) {
			desc += addProp('Antal personer i husstand', model.personInfo.familyMemberCount);
		}
		desc += addProp('Kunde har accepteret betingelser', model.contact_information.accept ? 'Ja' : 'Nej');
		desc += addProp('Email', model.contact_information.email);
		desc += addProp('Telefonnummer', model.contact_information.phone);
		desc += addProp('Rki', model.contact_information.rki);
		desc += addProp('Skader', model.contact_information.skader);
		desc += addProp('Betaling', model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
		desc += addProp('Samlet pris', Formatter.format(totalPrice) + ' kr');

		let description = addProp('Eksisterende kunde', model.personInfo.almbrandCustomer);
		if (model.personInfo.almbrandCustomer === 'ja') {
			const existingProducts = model.personInfo.existingAlmBrandProducts.includes(cms.discountProductNone)
				? []
				: model.personInfo.existingAlmBrandProducts;

			description += addProp(
				'Nuværende forsikringer',
				existingProducts.length > 0 ? existingProducts.join(', ') : 'Ingen'
			);
		}

		const rebate = getDiscount(cms);
		if (rebate.discount < 1) {
			description += addProp('<i>Plus</i>Kunde rabat inkluderet i pris', rebate.discountDisplay);
		}

		desc += description;

		desc += buildCreditCardInfo(model, cms);

		// products
		const productNames = await store.getters.getSelectableGroup('basket');
		let productDescription = '';

		productNames.forEach((productName) => {
			const calculator = store.getters.getCalculator(productName);
			productDescription += this.productHeader(productName, calculator);
			productDescription += this.productModel(productName, calculator);
		});

		desc += productDescription;

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}

	private productHeader(productName: string, calculator: any): string {
		let desc = `<hr><h2>${getCalculatorNameUi(productName)}</h2>`;
		if (!calculator?.model?.readyForBasket) {
			desc += '<b>Produkt ikke udfyldt - kontakt kunde</b><br>';
			return desc;
		}
		if (
			productName.includes(InsurancesProductEnum.INDBO_FORSIKRING) ||
			productName.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING) ||
			productName.includes(InsurancesProductEnum.BIL_FORSIKRING) ||
			productName.startsWith(InsurancesProductEnum.HUS_FORSIKRING)
		) {
			let high = '';
			if (calculator.model.personInfo?.thatchedRoof === 'ja') {
				high += '<li><h2><i>Stråtag</i></h2></li>';
			}
			if (calculator.model.calculation?.addressSupplement > 1) {
				high += '<li><h2><i>Adresse tillæg</i></h2></li>';
			}
			if (high !== '') {
				desc += `<ul>${high}</ul>`;
			}
		}

		const model = calculator.model;
		if (model.campaign.valid) {
			const id = model.campaign.ID ? model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
			if (model.campaign.uniqueKey) {
				desc += addProp(`${id} unik ID`, model.campaign.uniqueKey);
			}
			if (model.campaign.description) {
				desc += addProp(`Kampagne beskrivelse`, model.campaign.description);
			}
			if (model.campaign.groupId > 0) {
				desc += addProp(`Kampagne Gruppe kode`, model.campaign.groupId);
			}

			if (model.campaign.discount > 0) {
				desc += addProp(`Kampagne rabat inkluderet i pris`, model.campaign.discountDisplay);
			}
			if (model.campaign.tiaDiscount > 0) {
				desc += addProp(
					`Kampagne rabat fra TIA inkluderet i pris`,
					Formatter.format(100 * model.campaign.tiaDiscount) + ' %'
				);
			}

			if (model.campaign.splash) {
				desc += addProp(`Kampagne specifik`, model.campaign.splash);
			}
		}
		const pack = model.choosePackage?.selectedPackage;
		if (pack?.specialDiscount) {
			const discount = handleSpecialCarDiscounts(calculator.model, calculator.cms);
			if (discount.specialDiscountType === 'vehicleGroup') {
				desc += addProp(
					`Bilgruppe rabat (${calculator.cms.vehicleDiscountGroups.join(', ')}) inkluderet i pris`,
					Formatter.format(100 * pack.specialDiscount) + ' %'
				);
			} else if (discount.specialDiscountType === 'fuelType') {
				desc += addProp(
					`El/Hybrid rabat (bilgrp. ${calculator.cms.electricFuelTypeVehicleGroups.join(
						', '
					)}) inkluderet i pris`,
					Formatter.format(100 * pack.specialDiscount) + ' %'
				);
			} else {
				desc += addProp(`UKENDT rabat inkluderet i pris`, Formatter.format(100 * pack.specialDiscount) + ' %');
			}
		}

		return desc;
	}

	private productModel(productName: string, calculator: any): string {
		const name = productName.toLocaleLowerCase();
		if (!calculator || !calculator?.model?.readyForBasket) {
			// this.addProductToCheckout({productName: name , id: name});
			return '';
		}
		const model = calculator.model;

		const n = stripProductIndex(name);
		switch (n) {
			case InsurancesProductEnum.BIL_FORSIKRING:
				return this.getCar(model, calculator.cms);
			case InsurancesProductEnum.ULYKKES_FORSIKRING:
				return this.getAccident(model);
			case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
				return this.getAccidentChildren(model);
			case InsurancesProductEnum.INDBO_FORSIKRING:
				return this.getHome(model);
			case InsurancesProductEnum.HUNDE_FORSIKRING:
				return this.getDog(model);
			case InsurancesProductEnum.REJSE_FORSIKRING:
				return this.getTravel(model, calculator.cms);
			case InsurancesProductEnum.HUS_FORSIKRING:
				return this.getHouse(model, calculator.cms);
			case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
				return this.getHouse(model, calculator.cms);
			case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
				return this.getFamily(model);
			default:
				return '';
		}
	}

	private getAccident(model: any): string {
		const pack = model.choosePackage.selectedPackage;
		let desc = '';
		if (model.personInfo.otherName) {
			desc += addProp('Navn', model.personInfo.otherName);
			desc += addProp('Alder', model.personInfo.otherCustomerAge);
			desc += addProp('Cpr', model.personInfo.otherCpr);
		}

		desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

		if (model.additionalInfo.existInsurance === 'ja') {
			desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
		}

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<br><b>Ønsket forsikring</b><br>';
		desc += addProp('Forsikringstype', pack.name);
		desc += addProp('Arbejde', `${model.personInfo.work}`);
		desc += addProp('Arbejde-id', `${model.personInfo.workId}`);
		desc += '<br>';
		desc += addProp(
			'Pris',
			this.uiComp.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', 'Tia beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));

		resetCampaignStorage(model);
		return desc;
	}

	private getAccidentChildren(model: any): string {
		const pack = model.choosePackage.selectedPackage;

		let desc = '<br><b>Ønsket forsikring</b><br>';
		desc += addProp('Forsikringstype', pack.name);

		desc += childrenFields(model, true, true);

		desc += addProp(
			'Pris',
			this.uiComp.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', 'Tia beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));

		resetCampaignStorage(model);
		return desc;
	}

	private getHome(model: any): string {
		const pack = model.choosePackage.selectedPackage;
		let desc = '';
		desc += addProp('Stråtag', `${model.personInfo.thatchedRoof}`);
		desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

		if (model.additionalInfo.existInsurance === 'ja') {
			desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
		}

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<br><b>Ønsket forsikring</b><br>';
		desc += addProp('Forsikringstype', pack.name);
		desc += addProp('Stråtag', `${model.personInfo.thatchedRoof}`);
		desc += addProp('Selvrisiko', this.getExcessAmount(model));
		desc += addProp('Forsikret indbo sum', model.choosePackage.insuredValue);
		desc += addProp(
			'Pris',
			this.uiComp.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', 'FP_FP beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));
		resetCampaignStorage(model);
		return desc;
	}
	private getFamily(model: any): string {
		const pack = model.choosePackage.selectedPackage;
		let desc = '';

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<br><b>Ønsket forsikring</b><br>';
		desc += addProp('Forsikringstype', pack.name);

		desc += '<br><b>Indbo</b><br>';
		desc += addProp('Stråtag', `${model.personInfo.thatchedRoof}`);
		desc += addProp('Selvrisiko', this.getExcessAmount(model));
		desc += addProp('Forsikret indbo sum', model.choosePackage.insuredValue);
		desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

		if (model.additionalInfo.existInsurance === 'ja') {
			desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
		}

		desc += '<br><b>Børneulykke</b>';

		desc += childrenFields(model, false, true);

		desc += '<br>';

		desc += addProp(
			'Pris',
			this.uiComp.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', 'FP_FP beregning indbo, Tia beregning ulykke');
		desc += addProp('Dækninger', getCoverageNames(pack));
		resetCampaignStorage(model);
		return desc;
	}
	private getHouse(model: any, cms: any): string {
		const pack = model.choosePackage.selectedPackage;
		const productCode = model.productName === InsurancesProductEnum.HUS_FORSIKRING ? 'HU_HP' : 'FH_HP';
		let desc = '';

		if (model.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING) {
			desc += addProp('Fritidshus adresse', model.personInfo.holidayAddress.address);
		}

		desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

		if (model.additionalInfo.existInsurance === 'ja') {
			desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
		}

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<br><b>Ønsket forsikring</b><br>';
		desc += addProp('Forsikringstype', pack.name);
		if (model.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING && cms.insuredValue) {
			desc += addProp('Forsikringssum', Formatter.format(cms.insuredValue) + ' kr.');
		}

		desc += addProp('Selvrisiko', this.getExcessAmount(model));
		desc += addProp(
			'Pris',
			this.uiComp.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', productCode + ' beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));
		resetCampaignStorage(model);
		return desc;
	}

	private getDog(model: any): string {
		const pack = model.choosePackage.selectedPackage;
		let desc = '';
		desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

		if (model.additionalInfo.existInsurance === 'ja') {
			desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
		}

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<br><b>Ønsket forsikring</b><br>';

		desc += dogFields(model, pack);
		desc += addProp(
			'<br>Pris',
			this.uiComp.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', 'VP_VP beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));

		resetCampaignStorage(model);
		return desc;
	}

	private getTravel(model: any, cms: any): string {
		const pack = model.choosePackage.selectedPackage;
		let desc = '';
		// Kun Mastercard kampagne
		if (model.choosePackage.forfald) {
			desc += addProp('Forfald', model.choosePackage.forfald);
		}

		desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

		if (model.additionalInfo.existInsurance === 'ja') {
			desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
		}

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<br><b>Ønsket forsikring</b><br>';
		desc += addProp('Forsikringstype', pack.name);
		desc += addProp('Destination', model.choosePackage.segmentedProductProperty);
		try {
			if (model.choosePackage?.selectedPackage?.expandedCoverages?.includes('Afbestilling')) {
				const sumFormatted = Formatter.format(cms.cancelSum);
				model.overview.details.push('Afbestillingssum: ' + sumFormatted + ' kr.');
				desc += addProp('Afbestillingssum', sumFormatted + ' kr.');
			}
		} catch (e) {
			console.error(e);
			// Too bad
		}

		desc += addProp(
			'Pris',
			this.uiComp.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', 'RP_RP beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));
		resetCampaignStorage(model);
		return desc;
	}
	private getCar(model: any, cms: any): string {
		const pack = model.choosePackage.selectedPackage;
		let desc = '';
		if (model.personInfo.carOwnerYears && cms.carOwnerYears.indexOf(model.personInfo.carOwnerYears) > 0) {
			desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);
			if (model.additionalInfo.existInsurance === 'nej') {
				desc += addProp('Tidligere forsikring', model.additionalInfo.existFormerInsurance);
				if (model.additionalInfo.existFormerInsurance === 'ja') {
					desc += addProp('Tidligere selskab', model.additionalInfo.formerInsurance);
				}
			} else {
				desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
			}
		} else {
			desc += addProp('Eksisterende forsikring', 'Ingen');
			desc += addProp('Nuværende selskab', 'Ingen, ny bilist');
		}

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<br><b>Ønsket forsikring</b><br>';
		desc += addProp('Forsikringstype', pack.name);
		desc += addProp('Årlig kørselsbehov', model.carInfo.yearlyMileage);
		desc += addProp('Antal år med egen bil', model.personInfo.carOwnerYears);
		desc += addProp('Selvrisiko', this.getExcessAmount(model));
		desc += addProp(
			'Pris',
			this.uiComp.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', model.choosePackage.useTiaOffer ? 'Tia beregning' : 'AU_PB beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));

		desc += '<h2>Bil oplysninger</h2>';
		desc += addProp(
			'Nummerplade',
			model.carInfo.licenseplate ? model.carInfo.licenseplate.toUpperCase() : 'Ikke oplyst'
		);
		desc += addProp('Bil', model.carInfo.car);
		desc += addProp('Variant', model.carInfo.variantName);
		desc += addProp('Årgang', model.carInfo.year);
		desc += addProp('Kid', model.carInfo.kid + '');
		desc += addProp('VariantId', model.carInfo.variantId);
		desc += addProp('Vægt', model.carInfo.carWeight + '');
		desc += addProp('Drivmiddel', model.carInfo.fuelType + '');
		desc += addProp('VehicleGroup', model.carInfo.vehicleGroup);
		resetCampaignStorage(model);
		return desc;
	}

	private getExcessAmount(model: any): string {
		const inx = model.ownRiskOptions.indexOf(model.choosePackage.ownRiskId);
		return model.ownRiskLabels[inx];
	}
}
