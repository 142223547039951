import BuyAccidentChildren from './BuyAccidentChildrenComponent';
import { AccidentChildrenSettings, productId } from './AccidentChildrenSettings';
import { cloneDeep } from 'lodash';
import store from '@/store/store';
import AxiosService from '@/services/axiosService';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	finishCalculation,
	getDiscount,
	mapCoveragesFromProductConfig,
	prepareCalculations,
	setUpExcesses,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { Formatter } from '../services/ValidationService';
import { modalHttp250Toggle } from '../services/AlertService';
import { isDevelop } from '@/services/environmentService';

export default class AccidentCalculator {
	private axiosService: AxiosService;
	private buyAccident: BuyAccidentChildren; // component using calculator
	private model: any; // the data structure for state and properties
	private cms: AccidentChildrenSettings; // settings from EPI
	private calcConfig: any;
	private calculatorInfo: Array<any>;

	constructor(buyAccident: BuyAccidentChildren) {
		this.buyAccident = buyAccident;
		this.model = buyAccident.model;
		this.cms = buyAccident.cms;
		this.calculatorInfo = JSON.parse(buyAccident.calculatorInfoBlock.markup);

		const ulykkeCalculatorUrl =
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/accident/offers';
		this.axiosService = new AxiosService(ulykkeCalculatorUrl);

		this.setUpConfig();
	}

	public async setUpConfig() {
		setupCalculator(this, this.calculatorInfo);

		setupPackages(this.model, this.cms, this.calcConfig);

		mapCoveragesFromProductConfig(this.model, this.cms);
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model);
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		prepareCalculations(this.model, this.cms, false);
		// reset calculation
		await this.setUpConfig();
		// this.model.calculation.abCalc.calculated = true;
		try {
			const rebate = getDiscount(this.cms);
			this.buyAccident.model.calculation.discount = rebate.discount;
			this.buyAccident.model.calculation.discountDisplay = rebate.discountDisplay;

			const promises = [];
			this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
				calc.summedBasePrice = 0;
				calc.summedStatutoryFee = 0; // not returned by api
				calc.summedTotalPrice = 0;

				calc.summedYearlyBasePrice = 0;
				calc.summedYearlyStatutoryFee = 0; // not returned by api
				calc.summedYearlyPriceTotal = 0;
			});
			for (let i = 0; i <= this.model.familyInfo.personCountId; i++) {
				const age = this.model.familyInfo['age' + i];
				const workId = this.model.familyInfo['workId' + i];

				this.model.calculation.abCalc.packages.forEach((pack) => {
					const features = this.getFeatures(pack);
					promises.push(this.calculateQuickQuote(pack.name, age, features, workId));
				});
			}
			await Promise.all(promises);
			this.sumUpPrices();
			this.model.calculation.abCalc.calculated = true;

			finishCalculation(this.model, this.cms);

			updateCalcKeys(this.model);
			return Promise.resolve(true);
		} catch (err) {
			console.error('Accident offer', err);
			modalHttp250Toggle(this.cms, 400, 'child accident calculator', this);
			store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;
			this.model.stopSpinner = false;
			return Promise.resolve(false);
		}
	}

	private sumUpPrices() {
		this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
			calc.basePrice = calc.summedBasePrice;
			calc.totalPrice = calc.summedTotalPrice;
			calc.yearlyBasePrice = calc.summedYearlyBasePrice;
			calc.yearlyPriceTotal = calc.summedYearlyPriceTotal;
			calc.totalPriceDisplay = Formatter.format(calc.summedTotalPrice) + ' kr.';
			calc.yearlyPriceTotalDisplay = Formatter.format(calc.summedYearlyPriceTotal) + ' kr.';
		});
	}

	private async calculateQuickQuote(
		productType: string,
		childAge: string,
		features: Array<any>,
		workId: string
	): Promise<any> {
		let customerAge = parseInt(childAge);
		if (customerAge < this.cms.minChildAge) {
			customerAge = this.cms.minChildAge;
		}
		if (!features) {
			return Promise.reject();
		}
		const createOffer: any = {
			product: {
				product_name: productId,
				features,
				parameters: {
					age: customerAge,
					postalCode: this.model.personInfo.zipCode,
				},
			},
		};

		workId ? (createOffer.product.parameters.employment = workId) : undefined;

		try {
			const res = await this.axiosService.postRetry(undefined, createOffer);
			const offer = res.data;
			if (!offer || !offer.price || !offer.price.annually) {
				throw new Error();
			}
			this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
				if (calc.name === productType) {
					let totalPrice = offer.price.monthly;
					calc.basePrice = totalPrice;

					calc.statutoryFee = 0; // not returned by api

					// pluscustomer discount
					let discount = this.model.calculation.discount;
					if (this.model.campaign.valid) {
						discount -= this.model.campaign.discount;
					}

					totalPrice *= discount;

					// totalPrice *= this.buyAccident.model.calculation.discount;

					// // campaign discount
					// if (this.model.campaign.valid)  {
					//     totalPrice -= (totalPrice * this.model.campaign.discount);
					// }

					calc.totalPrice = Math.round(totalPrice);

					totalPrice = offer.price.annually;
					calc.yearlyBasePrice = totalPrice;
					calc.yearlyStatutoryFee = 0; // not returned by api

					// pluscustomer discount
					totalPrice *= discount;
					// totalPrice *= this.model.calculation.discount;

					// // campaign discount
					// if (this.model.campaign.valid)  {
					//     totalPrice -= (totalPrice * this.model.campaign.discount);
					// }

					calc.yearlyPriceTotal = Math.round(totalPrice);

					calc.summedBasePrice += calc.basePrice;
					calc.summedStatutoryFee = 0; // not returned by api
					calc.summedTotalPrice += calc.totalPrice;

					calc.summedYearlyBasePrice += calc.yearlyBasePrice;
					calc.summedYearlyPriceTotal += calc.yearlyPriceTotal;
					calc.summedYearlyStatutoryFee = 0; // not returned by api
				}
			});

			return Promise.resolve();
		} catch (err) {
			console.error(err);
			modalHttp250Toggle(this.cms, 400, 'child accident calculator', this);
			if (isDevelop && this.cms.mockData) {
				// MOCK
				console.warn('USING MOCK DATA!');

				let add = 2000;
				this.model.calculation.abCalc.packs
					.get(this.model.calculation.abCalc.excessIdDefault)
					.forEach((calc) => {
						add += 100;
						if (calc.name === productType) {
							let totalPrice = add;
							calc.basePrice = totalPrice;
							calc.statutoryFee = 0; // not returned by api
							if (this.model.campaign.valid) {
								totalPrice -= totalPrice * this.model.campaign.discount;
							}

							calc.totalPrice = Math.round(totalPrice * this.model.calculation.discount);
							calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

							totalPrice = 12 * add * 0.97;
							calc.yearlyBasePrice = totalPrice;
							calc.yearlyStatutoryFee = 0; // not returned by api

							if (this.model.campaign.valid) {
								totalPrice -= totalPrice * this.model.campaign.discount;
							}
							calc.yearlyPriceTotal = Math.round(totalPrice * this.model.calculation.discount);
							calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
							updateCalcKeys(this.model);
						}
					});
			} else {
				return Promise.reject(err);
			}
		}
	}

	private getFeatures(pack) {
		const features = [];
		pack.includedCoveragesExpandedIds.forEach((riskId) => {
			const cov = cloneDeep(
				this.model.calculation.abCalc.coveragesDetails.filter((coverage) => riskId === coverage.riskId)[0]
			);
			cov.name = cov.riskId;
			features.push(cov);
		});
		return features;
	}
}
