import { HtmlBlock } from '@/cms/definitions/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings';

export class BasketSettings extends BuyInsuranceSettings {
	public subtitleDiscount = '<i>Plus</i>Kunde rabat ';
	public subtitlePrice = ' Samlet pris ';
	public waitForEmail = 0;
	public rkiHeaderDFIM: string;
	public rkiPopoverDFIM: string;
	public rkiPopupTitleDFIM: string;
	public rkiPopupContentDFIM: string;
	public shortMainTitleChooseInsurance: string; // = 'Forsikringer';
	public shortTitleChooseInsurance: string; // = 'Beregn';

	public moreInsurancesBlockShow: boolean;
	public moreInsurancesBlockHeader: string;
	public moreInsurancesBlockText: string;
	public moreInsurancesBlockBtnLabel: string;
	public moreInsurancesBlockBtnClass: string;
	public moreInsurancesBlockBtnlink: string;
	public moreInsurancesBlockMaxWidth: string;

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadBasketSettings();
	}

	/**
	 * load settings from CMS if present
	 */
	private loadBasketSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('subtitlediscount=') > -1) {
					this.subtitleDiscount = this.getSettingsValue(setting_);
				} else if (setting.indexOf('subtitleprice=') > -1) {
					this.subtitlePrice = this.getSettingsValue(setting_);
				} else if (setting.indexOf('waitforemail=') > -1) {
					this.waitForEmail = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('rkiheaderdfim=') > -1) {
					this.rkiHeaderDFIM = this.getSettingsValue(setting_);
				} else if (setting.indexOf('rkipopoverdfim=') > -1) {
					this.rkiPopoverDFIM = this.getSettingsValue(setting_);
				} else if (setting.indexOf('rkipopuptitledfim=') > -1) {
					this.rkiPopupTitleDFIM = this.getSettingsValue(setting_);
				} else if (setting.indexOf('rkipopupcontentdfim=') > -1) {
					this.rkiPopupContentDFIM = this.getSettingsValue(setting_);
				} else if (setting.indexOf('shortmaintitlechooseinsurance=') > -1) {
					this.shortMainTitleChooseInsurance = this.getSettingsValue(setting_);
				} else if (setting.indexOf('shorttitlechooseinsurance=') > -1) {
					this.shortTitleChooseInsurance = this.getSettingsValue(setting_);
				} else if (setting.indexOf('moreinsurancesblockshow=') > -1) {
					this.moreInsurancesBlockShow = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('moreinsurancesblockheader=') > -1) {
					this.moreInsurancesBlockHeader = this.getSettingsValue(setting_);
				} else if (setting.indexOf('moreinsurancesblocktext=') > -1) {
					this.moreInsurancesBlockText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('moreinsurancesblockbtnlabel=') > -1) {
					this.moreInsurancesBlockBtnLabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('moreinsurancesblockbtnclass=') > -1) {
					this.moreInsurancesBlockBtnClass = this.getSettingsValue(setting_);
				} else if (setting.indexOf('moreinsurancesblockbtnlink=') > -1) {
					this.moreInsurancesBlockBtnlink = this.getSettingsValue(setting_);
				} else if (setting.indexOf('moreinsurancesblockmaxwidth=') > -1) {
					this.moreInsurancesBlockMaxWidth = this.getSettingsValue(setting_);
				}
			});
		} catch (e) {
			console.error(e);
		}
	}
}
