import { exception } from '@/appinsights/logging/ApplicationInsightLog';
import store from '@/store/store';

const retryRecaptchaTimeout = 200;

/** Experience problems getting token - therefore retry */
export const getReCaptchaToken = async () => {
	let res = await doGetReCaptchaToken();
	if (!res) {
		res = await new Promise(() => setTimeout(() => doGetReCaptchaToken, retryRecaptchaTimeout));
	}
	return res;
};

const doGetReCaptchaToken = async () => {
	try {
		if ((window as any).grecaptcha?.execute) {
			const token = await (window as any).grecaptcha.execute(store.getters.getRecaptchaSiteKey, {
				action: 'online_sale',
			});
			return token;
		} else {
			throw 'no grecaptcha';
		}
	} catch (e) {
		exception(e, 'Failed ReCaptcha token');
		return undefined;
	}
};

export const loadRecaptchaScript = () => {
	const recaptchaScript = document.createElement('script');
	recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + resolveReCaptchaToken());
	recaptchaScript.setAttribute('type', 'text/javascript');
	// recaptchaScript.setAttribute('async', 'async'); // sometimes race condition on load
	document.head.appendChild(recaptchaScript);
};

const resolveReCaptchaToken = () => {
	const recaptchaSiteKey = document.querySelector('#app')?.getAttribute('data-SiteKey')?.trim() || '';
	store.dispatch('setRecaptchaSiteKey', recaptchaSiteKey);
	return recaptchaSiteKey;
};
