import Coverage from '../components/coverageComponent/Coverage';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/services/environmentService';
// list and order of steps

export const STEPS: Array<string> = [
	STEP.DOG_INFO,
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class Model {
	public static model = {
		campaign: {
			ID: undefined,
			valid: false,
			init: false,
			hasContentPage: false,
			subtitle: undefined,
			uniqueKey: undefined,
			discount: undefined,
			productStepDiscount: undefined,
		},
		dogInfo: {
			subtitle: undefined,
			dogCount: undefined, // dogCount value
			dogName1: undefined,
			dogName2: undefined,
			dogType1: undefined, // string
			dogType2: undefined,
			dogTypeId1: undefined, // string
			dogTypeId2: undefined,
			gender1: undefined, // T, H, U -> TÆVE, HAN, Uoplyst
			gender2: undefined,
			birthdate1: undefined, // dd-MM-yyyy
			birthdate2: undefined,
			// answers for both dogs if 2 dogs selected
			healthy: undefined, // Y
			beenUnhealthy: undefined, // N
			vet: undefined, // N
			vaccine: undefined, // Y
		},
		personInfo: {
			subtitle: undefined,
			almbrandCustomer: undefined,
			existingAlmBrandProducts: [],
		},

		choosePackage: {
			subtitle: undefined,
			ownRiskId: undefined,
			selectedPackage: undefined,
			monthYear: undefined, //this.monthYearArray[0].value,
		},
		contact_information: {
			subtitle: undefined,
			name: undefined,
			email: undefined,
			phone: undefined,
			cpr: undefined,
			customerNo: undefined,
			accept: false,
		},
		overview: {
			subtitle: undefined,
			highlights: undefined,
			details: undefined,
		},
		additionalInfo: {
			subtitle: undefined,
			existInsurance: undefined,
			existingInsurance: undefined,
			existingInsuranceId: undefined,
			validNow: undefined,
			validFromDate: undefined,
			skader: undefined,
			rki: undefined,
		},
		payment: {
			subtitle: undefined,
			regNo: undefined,
			kontoNo: undefined,
			userSelected: false,
		},
		pristineStep: new Map([
			[STEP.DOG_INFO.valueOf(), true],
			[STEP.PERSON_INFO.valueOf(), true],
			[STEP.PACKAGE.valueOf(), true],
			[STEP.ADDITIONAL_INFO.valueOf(), true],
			[STEP.OVERVIEW.valueOf(), true],
			[STEP.CONTACT_INFORMATION.valueOf(), true],
			[STEP.PAYMENT.valueOf(), true],
		]),
		productName: InsurancesProductEnum.HUNDE_FORSIKRING,
		ownRiskOptions: [] as Array<number>,
		ownRiskLabels: [] as Array<string>,
		calculation: {
			isCalculated: false,
			updatePrices: ['pr1', 'pr2', 'pr3'],
			excessIdDefault: undefined,
			packageIds: undefined,
			packages: undefined,
			abCalc: {
				excesses: [],
				packs: new Map<number, Object>(),
			},
			allCoverages: new Map<number, Map<number, Array<Coverage>>>(),
			discount: undefined,
			discountDisplay: undefined,
		},
		showReceipt: false,
		currentCardName: 'none',
		updateAll: 'updateAll',
		readyForBasket: false,
		multipleProducts: false,
		showExistingAlmBrandProducts: true,
	};

	public static mock() {
		if (isDevelop) {
			Object.assign(Model.model.personInfo, {
				almbrandCustomer: 'nej',
				address: 'Adolphsvej 19, 2820 Gentofte',
				houseNr: '19',
				streetName: 'Adolphsvej',
				zipCode: '2820',
				zipName: 'Gentofte',
				// almbrandCustomer:"ja",
				// existingAlmBrandProducts:['Indboforsikring', 'Husforsikring'],
			});

			Object.assign(Model.model.dogInfo, {
				subtitle: undefined,
				dogCount: 1,
				dogName2: 'King',
				dogName1: 'Fido',
				dogType2: 'Engelsk bulldog',
				dogType1: 'Barbet',
				dogTypeId2: '101',
				dogTypeId1: '25',
				gender2: 'T',
				gender1: 'H',
				birthdate1: '12-10-2021',
				birthdate2: '12-10-2021',
				// answers for both dogs if 2 dogs selected
				healthy: 'ja',
				beenUnhealthy: 'nej',
				vet: 'nej',
				vaccine: 'ja',
			});

			Object.assign(Model.model.additionalInfo, {
				existInsurance: 'nej',
				validNow: 'ja',
				skader: '0',
				rki: 'nej',
			});

			Object.assign(Model.model.contact_information, {
				accept: true,
				cpr: '2342342323',
				customerNo: undefined,
				email: 'abklku@almbrand.dk',
				name: 'Test Testesen',
				phone: '11111111',
				subtitle: 'Test Testesen',
			});
			// Object.assign(Model.model.payment, {
			//     kontoNo:'123456',
			//     regNo:'1233',
			// });
		}
	}
}
