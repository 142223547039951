import { Options, Vue } from 'vue-class-component';
import PageTitleComponent from '@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import BuyCarComponent from './car/BuyCarComponent.vue';
import BuyAccidentComponent from './accident/BuyAccidentComponent.vue';
import BuyAccidentChildrenComponent from './accidentChildren/BuyAccidentChildrenComponent.vue';
import BuyHomeComponent from './home/BuyHomeComponent.vue';
import BuyHouseComponent from './house/BuyHouseComponent.vue';
import BuyTravelComponent from './travel/BuyTravelComponent.vue';
import BuyDogComponent from './dog/BuyDogComponent.vue';
import BuyFamilyComponent from './family/BuyFamilyComponent.vue';
import ContactComponent from './components/ContactComponent.vue';
import {
	UPDATE_ACTIVE_CALCULATOR,
	CALCULATOR_LOADED,
	BASKET_LOADED,
	RESET_CALCULATORS,
} from '@/store/modules/calculatorContext';
import BasketWidgetComponent from './components/BasketWidgetComponent.vue';
import BasketComponent from './basket/BasketComponent.vue';
import { CalculatorPage, HtmlBlock, CardBlock, HeaderCardBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import CookieFooter from '@/sharedcomponents/pageComponents/cookieFooter/cookieFooter.vue';
import { sendMailLead } from './services/OrderService';
import { loadCustomer } from './services/UserLoginService';
import { AppSendMessage, isApp } from '@/services/environmentService';
import { mapState } from 'vuex';
import Menu from '@/sharedcomponents/pageComponents/menu/Menu.vue';
import { trackBeginCheckoutViewItem, trackLoadInsurance } from '@/services/trackingUtility';
import { handleNavigationTracking, headerBadgeShown } from './services/NavigationService';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
export interface CalculatorComponent {
	model: any;
	cms: any;
	buildDescriptionForEmail: Function;
	addResetSubscription: Function;
}

@Options({
	name: 'BuyInsuranceComponent',
	components: {
		PageTitleComponent,
		BuyCarComponent,
		BuyAccidentComponent,
		BuyAccidentChildrenComponent,
		BuyHomeComponent,
		BuyHouseComponent,
		BuyTravelComponent,
		BasketComponent,
		BuyFamilyComponent,
		BuyDogComponent,
		ContactComponent,
		BasketWidgetComponent,
		Menu,
		CookieFooter,
		UsabillaInPageComponent,
	},
	computed: mapState<any>({
		menuModel: (state) => state.menuModel,
	}),

	props: {
		model: Object,
	},
})
export default class BuyInsuranceComponent extends Vue {
	public menuModel!: Array<any>;

	model: CalculatorPage; // epi contentModel

	public contentBlocks: Array<CardBlock> = [];
	public receiptBlock: CardBlock;
	public settingsBlock: HtmlBlock;
	public calculatorInfoBlock: HtmlBlock;
	public usabillaBlock: any = null;
	public usabillaProductBlock: any = null;
	public contentBlocksLoaded = false;

	public cms: any = null; // from the selected calculator store, used for content i contact component
	public productName: string = null;
	public renderPage = false;
	public headerBadgeTxt: string = null;
	public hasBadgeText = false;
	public preTitle = '...';
	public mainTitle = '...';
	public loadingAgreements = false;
	public static sendLeadMail = true;
	private static onBeforeLoadRegistered = false;

	public beforeCreate() {
		const unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === CALCULATOR_LOADED || action.type === BASKET_LOADED) {
				this.cms =
					action.type === CALCULATOR_LOADED
						? store.getters.getActiveCalculator.cms
						: store.getters.getBasket.cms;
				this.handleUser();
				this.handleTitles();
				this.renderPage = true;
				this.setupMailLead();
				// togglePopup(this.cms, {show: true, id:'Test'})
				if (this.productName !== 'kurv') {
					trackLoadInsurance(this.productName, this.cms.defaultCard).then(() =>
						trackBeginCheckoutViewItem(this.productName, true)
					);
				} else {
					// const idName = 'overview_basket';
					// trackViewCard(idName, idName, false).then( () => trackViewItemList(idName, idName,true,false).then( () => trackBeginCheckoutViewItem(this.productName,true)));
					trackBeginCheckoutViewItem(this.productName, true);
				}
				// trackBeginCheckout(this.productName);
				unsubscribe();
				// only relevant on direct pageLoad
				setTimeout(() => {
					if (!store.state.trackingContext.isFirstPageEventFired) {
						console.log('FirstPageLoad Fired From product');

						handleNavigationTracking();
					}
				}, this.cms.animationTimeout);
			}
		});
		store.subscribeAction((action, state) => {
			if (action.type === RESET_CALCULATORS) {
				// don't send leadMail, customer has ordered product(s)
				BuyInsuranceComponent.sendLeadMail = false;
				// don't show productUsabilla
				this.usabillaProductBlock = undefined;
			}
		});
	}

	public async beforeMount() {
		window.addEventListener('resize', this.handleTitles);
	}

	public async mounted() {
		this.productName = this.model.preTitle.toLocaleLowerCase();
		if (this.productName !== 'kurv') {
			await store.dispatch(UPDATE_ACTIVE_CALCULATOR, this.productName);
		}
		this.handleContentBlocks();
	}

	public get showSpinner() {
		return store.state.showSpinner;
	}
	public get calculating() {
		return store.state.showSpinner && store.state.calculatorContext.calculating;
	}

	public get isLoggedInAsApp() {
		return isApp();
	}

	private async handleUser() {
		this.loadingAgreements = true;
		await loadCustomer(this.cms);
		this.loadingAgreements = false;
		BuyInsuranceComponent.sendLeadMail = this.cms.sendLeadMail;
	}

	private setupMailLead() {
		if (BuyInsuranceComponent.onBeforeLoadRegistered) {
			return;
		}
		window.addEventListener('beforeunload', (evt) => {
			if (BuyInsuranceComponent.sendLeadMail) {
				BuyInsuranceComponent.sendLeadMail = false;
				sendMailLead();
			}
			return undefined;
		});
		BuyInsuranceComponent.onBeforeLoadRegistered = true;
	}

	public beforeUnmount() {
		window.removeEventListener('resize', this.handleTitles);
	}

	private handleTitles() {
		const calculatorInx = store.getters.getActiveCalculatorInx;
		let calcNo = '';
		if (calculatorInx > 0 && this.productName !== 'kurv') {
			calcNo = ' ' + (calculatorInx + 1);
		}
		try {
			if (isApp()) {
				this.preTitle = undefined; // Not used in App
				this.mainTitle = this.cms.shortTitle;
				this.mainTitle += calcNo;
				AppSendMessage({ MAIN_TITLE: this.model.mainTitle });
			} else if (screen.width < 768) {
				this.preTitle = this.cms.shortTitle + calcNo;
				this.mainTitle = this.cms.shortTitle !== 'Kurv' ? this.cms.shortMainTitle : this.model.mainTitle;
			} else {
				this.preTitle = this.model.preTitle + calcNo;
				this.mainTitle = this.model.mainTitle;
			}
		} catch (error) {
			this.preTitle = this.model.preTitle + calcNo;
			this.mainTitle = this.model.mainTitle;
		}
	}

	private handleContentBlocks() {
		const blocks: Array<CardBlock> = [];
		this.settingsBlock = { markup: '' } as HtmlBlock;
		this.calculatorInfoBlock = { markup: '' } as HtmlBlock;
		this.model.mainArea.forEach((block) => {
			if (block.contentType === 'CardBlock') {
				if (block.name === 'receipt' || block.name === 'reciept') {
					this.receiptBlock = block as CardBlock;
					this.receiptBlock.name = 'receipt';
				} else {
					blocks.push(block as CardBlock);
				}
			} else if (block.contentType === 'HeaderCardBlock') {
				const headerBlock = block as HeaderCardBlock;
				const text = headerBlock.text && headerBlock.text.trim() === '' ? '' : headerBlock.text;
				const hasText = text !== '';
				const hasCss = headerBlock.css && headerBlock.css?.trim() !== '';

				const isHeaderBadgeShown = headerBadgeShown(block.contentId);
				if (!isHeaderBadgeShown) {
					if (hasText) {
						this.headerBadgeTxt = this.headerBadgeTxt ? this.headerBadgeTxt + text : text;
						this.hasBadgeText = true;
					}
					if (hasCss) {
						this.headerBadgeTxt = this.headerBadgeTxt
							? this.headerBadgeTxt + headerBlock.css
							: headerBlock.css;
					}
					if (headerBlock.script) {
						const s = document.createElement('script');
						s.type = 'text/javascript';
						try {
							s.appendChild(document.createTextNode(headerBlock.script));
							document.body.appendChild(s);
						} catch (e) {
							console.error(e);
						}
					}
				}
			} else if (block.contentType === 'HtmlBlock') {
				if (block.name.toLocaleLowerCase() === 'global settings') {
					// global settings first
					this.settingsBlock.markup = (block as HtmlBlock).markup + this.settingsBlock.markup;
				} else if (block.name.toLocaleLowerCase().endsWith('settings')) {
					this.settingsBlock.markup += (block as HtmlBlock).markup;
				} else if (block.name.toLocaleLowerCase().endsWith('calculatorinfo')) {
					this.calculatorInfoBlock.markup += (block as HtmlBlock).markup;
				}
			} else if (block.contentType === 'UsabillaInPageBlock') {
				const usabillaId = (block as any)?.usabillaId;
				switch (usabillaId) {
					case store.state.UsabillaReceiptId:
						this.usabillaBlock = block;
						break;
					case store.state.UsabillaProductId:
						this.usabillaProductBlock = block;
						break;
					default:
						break;
				}
			}
		});
		this.contentBlocks = blocks;
		this.contentBlocksLoaded = true;
	}

	public get isCar(): boolean {
		return this.productName === InsurancesProductEnum.BIL_FORSIKRING;
	}
	public get isAccident(): boolean {
		return this.productName === InsurancesProductEnum.ULYKKES_FORSIKRING;
	}
	public get isAccidentChildren(): boolean {
		return this.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING;
	}
	public get isHome(): boolean {
		return this.productName === InsurancesProductEnum.INDBO_FORSIKRING;
	}
	public get isFamily(): boolean {
		return this.productName === InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING;
	}
	public get isHouse(): boolean {
		return (
			this.productName === InsurancesProductEnum.HUS_FORSIKRING ||
			this.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING
		);
	}
	public get isTravel(): boolean {
		return this.productName === InsurancesProductEnum.REJSE_FORSIKRING;
	}
	public get isDog(): boolean {
		return this.productName === InsurancesProductEnum.HUNDE_FORSIKRING;
	}
	public get isBasket(): boolean {
		return this.productName === 'kurv';
	}
}
