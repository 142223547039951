import {
	BasketProductsEnum,
	CALCULATOR_UPDATE_COMMON_MODEL,
	UPDATE_CALCULATOR_INDEX,
} from '@/store/modules/calculatorContext';
import store from '@/store/store';
import { nextTick } from 'vue';
import VueScrollTo from 'vue-scrollto';
import { isSyncWithCommonModel } from './CommonModelService';
import {
	getCalculatorIndex,
	getCalculatorNameUi,
	getShortProductName,
	getUrlProductName,
	stripProductIndex,
} from './ProductService';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { SelectableData, UPDATE_SELECTABLE } from '@/store/modules/selectableContext';
import { modalWithExit, togglePopup } from './AlertService';
import { STEP, STEPS_EXCLUDE_BASKET } from './UiStepService';
import { isWeb } from '@/services/environmentService';
import { trackGeneralPageView, trackProductStep } from '@/services/trackingUtility';
import { resetSelectedCalculation } from './CalculationService';
import { FIRE_GA4_EVENTS } from '@/store/modules/trackingContext';

export const path = '/';

export const GO_TO_PACKAGE_STEPS: Array<string> = [
	STEP.ADDITIONAL_INFO,
	STEP.CONTACT_INFORMATION,
	STEP.OVERVIEW,
	STEP.PAYMENT,
	STEP.ORDER,
];
const All_STEPS: Array<string> = [
	STEP.DOG_INFO,
	STEP.CAR_INFO,
	STEP.CHILDREN_INFO,
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

const doTrack = ['/forsikringer', 'step='];
const pageLoadWait = 1250;
const urlPollingInterval = 500;

export const setFirstCard = async (comp) => {
	store.state.showSpinner = true;
	// check if product is ready for basket
	const basket = store.getters.getSelectableGroup('basket');
	if (basket.length > 1) {
		if (isPreviousStepsValid(STEP.CONTACT_INFORMATION, comp)) {
			// abnormal navigation (basketWidget), set correct state on product
			comp.model.multipleProducts = true;
			comp.model.readyForBasket = true;
			store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, {
				fromCommonModel: false,
			});
			// goto next product or basket
			if (handleProductBasketMove(comp.model.currentCardName, comp)) {
				store.state.showSpinner = false;
				return;
			}
		}
	}

	setTimeout(() => {
		let moveOn = true;
		if (comp.model.currentCardName && comp.model.currentCardName !== 'none') {
			// coming from outside (basket)
			const validStep = All_STEPS.includes(comp.model.currentCardName);
			if (validStep) {
				// not campaign page
				const isGotoPackageStep = GO_TO_PACKAGE_STEPS.includes(comp.model.currentCardName);
				if (moveOn && isGotoPackageStep) {
					// on product load always goto calculation if currentCard is
					comp.model.currentCardName = comp.cms.defaultCard;
					resetSelectedCalculation(comp.model);
					nextStep(STEP.PACKAGE, true, comp);
					moveOn = false;
				}
			}
		}
		if (moveOn && comp.model.campaign.valid && comp.model.campaign.hasContentPage) {
			nextStep(comp.model.campaign.ID, true, comp);
			moveOn = false;
		}
		if (moveOn) {
			comp.model.currentCardName = 'none';
			nextStep(comp.cms.defaultCard, true, comp);
		}
		store.state.showSpinner = false;
	}, comp.cms.initialTimeout);
	comp.componentInit = true;
};

// when nextbtn is clicked - check for valid
export const nextStep = async (gotoCardName: string, addToHistory: boolean, comp) => {
	const currentCardName = comp.model.currentCardName;

	if (currentCardName === gotoCardName) {
		return;
	}
	if (currentCardName === STEP.PERSON_INFO) {
		const res = isSyncWithCommonModel(comp);
		if (!res.ok) {
			let msg = comp.cms.wrongAddressPopupContent;
			msg = msg.replace('@zipTxt', res.msg);
			const modal = modalWithExit(comp.cms);
			modal.title = comp.cms.wrongAddressPopuptitle;
			modal.content = msg;
			modal.track = true;
			modal.trackToken = 'addressMismatch';
			modal.id = 'addressMismatch';
			togglePopup(comp.cms, modal);
			return;
		}
	}
	if (!isPreviousStepsValid(gotoCardName, comp)) {
		comp.model.pristineStep.set(currentCardName, false);
		return;
	}

	if (isStepPreviousToCurrent(gotoCardName, comp)) {
		if (await comp.gotoCard(gotoCardName)) {
			trackNextStep(gotoCardName, addToHistory, comp);
		}
		return;
	}
	if (!comp.model.readyForBasket && !comp.isValid(currentCardName)) {
		comp.model.pristineStep.set(currentCardName, false);
		comp.model.updateAll += 1;
		return;
	}
	if (handleProductBasketMove(gotoCardName, comp)) {
		return;
	}
	if (await comp.gotoCard(gotoCardName)) {
		trackNextStep(gotoCardName, addToHistory, comp);
	}
};

export const addStepToHistory = (add: boolean, comp) => {
	try {
		if (add) {
			if (isWeb()) {
				let url = comp.contentUrl?.endsWith('/')
					? comp.contentUrl.substring(0, comp.contentUrl.length - 1)
					: comp.contentUrl;
				if (!url) {
					url = comp.$route.path;
				}
				window.history.pushState(
					{
						step: comp.model.currentCardName,
					},
					comp.model.currentCardName,
					`${url}?step=${comp.model.currentCardName}`
				);
			}
			//  else {
			//     const href = location.href;
			//     const appInx = href.indexOf('app#') + 4;
			//     let url = href.substring(0, appInx);
			//     let remainUrl = href.substring(appInx, href.length);
			//     const indexStep = remainUrl.indexOf('step=') -1; // $ before step=
			//     if (indexStep > 1) {
			//         remainUrl = remainUrl.substring(0, indexStep);
			//     }

			//     //const _url = urlService.ensureCorrectLink(remainUrl);
			//     const _url = remainUrl;
			//     const newUrl = url + _url;

			//     window.history.pushState({
			//         step: comp.model.currentCardName
			//     },
			//         comp.model.currentCardName,
			//         `${newUrl}&step=${comp.model.currentCardName}`
			//     );
			// }
		}
	} catch (e) {
		console.error(e);
	}
};

export const gotoAddInsurances = (step: string, comp) => {
	if (step === STEP.OVERVIEW && isPreviousStepsValid(step, comp)) {
		comp.model.multipleProducts = true;
		comp.model.readyForBasket = true;
		store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, { fromCommonModel: false });
	}
	// const path = urlService.ensureCorrectLink(path + 'forsikringer')
	const path_ = path + 'forsikringer';
	comp.$router.push({ path: path_ });
};

// preselect products in basket if they are present in Url
export const handlePreselectedProducts = async (query) => {
	try {
		const promises = [];
		if (query.products) {
			const original = query.products.toLocaleLowerCase();
			const productsRaw = original.split(',');
			const basket = new Array<string>();
			const products = [];
			// handle multiple insurances (cars, accidents)
			let carInx = -1;
			let accidentInx = -1;
			productsRaw.forEach((prod) => {
				prod = prod.trim();
				if (prod.startsWith(InsurancesProductEnum.BIL_FORSIKRING)) {
					carInx++;
					products.push(stripProductIndex(prod) + carInx); // insure indexes on each instance
				} else if (prod.startsWith(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
					accidentInx++;
					products.push(stripProductIndex(prod) + accidentInx); // insure indexes on each instance
				} else {
					products.push(prod);
				}
			});

			products.forEach((product) => {
				let p = product;
				if (p === stripProductIndex(p)) {
					// no index on product, set to 0
					p += '0';
				}
				switch (p) {
					case BasketProductsEnum.BIL_FORSIKRING0:
					case BasketProductsEnum.BIL_FORSIKRING1:
					case BasketProductsEnum.BIL_FORSIKRING2:
					case BasketProductsEnum.BIL_FORSIKRING3:
					case BasketProductsEnum.BIL_FORSIKRING4:
					case BasketProductsEnum.BIL_FORSIKRING5:
					case BasketProductsEnum.INDBO_FORSIKRING0:
					case BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0:
					case BasketProductsEnum.ULYKKES_FORSIKRING0:
					case BasketProductsEnum.ULYKKES_FORSIKRING1:
					case BasketProductsEnum.ULYKKES_FORSIKRING2:
					case BasketProductsEnum.ULYKKES_FORSIKRING3:
					case BasketProductsEnum.ULYKKES_FORSIKRING4:
					case BasketProductsEnum.ULYKKES_FORSIKRING5:
					case BasketProductsEnum.ULYKKES_FORSIKRING6:
					case BasketProductsEnum.ULYKKES_FORSIKRING7:
					case BasketProductsEnum.ULYKKES_FORSIKRING8:
					case BasketProductsEnum.ULYKKES_FORSIKRING9:
					case BasketProductsEnum.BOERNEULYKKES_FORSIKRING0:
					case BasketProductsEnum.HUNDE_FORSIKRING0:
					case BasketProductsEnum.HUS_FORSIKRING0:
					case BasketProductsEnum.FRITIDSHUS_FORSIKRING0:
					case BasketProductsEnum.REJSE_FORSIKRING0:
						if (!basket.includes(p)) {
							basket.push(p);
						}
						break;
					default:
						break;
				}

				basket.forEach((p) => {
					const toggle: SelectableData = {
						group: 'basket',
						id: p,
						isSelected: true,
						sortMultiple: true,
					};
					promises.push(store.dispatch(UPDATE_SELECTABLE, toggle));
				});
			});
			await Promise.all(promises);
		}
	} catch (error) {
		// too bad
	}
};

export const gotoFirstProductInBasket = async (): Promise<string> => {
	const basket = store.getters.getSelectableGroup('basket');
	const results = [];
	const multiple = basket.length > 1;
	if (multiple) {
		basket.forEach((name) => {
			const calc = store.getters.getCalculator(name);
			if (!calc) {
				results.push(name);
			} else if (!calc.model.readyForBasket) {
				results.push(name);
				calc.model.multiple = true;
			}
		});
	} else if (basket.length === 1) {
		// only 1 product, not basket mode
		results.push(basket[0]);
	} else {
		results.push('forsikringer'); // no products at all goto choose page
	}

	if (multiple && results.length === 0) {
		// all products calculated and validated - goto basket
		results.push('kurv');
	}

	const calculatorIndex = getCalculatorIndex(results[0]);
	if (calculatorIndex > -1) {
		await store.dispatch(UPDATE_CALCULATOR_INDEX, calculatorIndex);
	}
	const page: string = getUrlProductName(results[0]);
	return path + page;
};

/**
 * Only show headerCards once in session
 * @param contentId
 * @returns
 */
export const headerBadgeShown = (contentId: number) => {
	// TODO KLK add this block if only show header of a type once in session
	// if (store.state.headerBadgeShown.get(contentId)) {
	//     return true;
	// }
	// store.state.headerBadgeShown.set(contentId, true);
	return false;
};

// Not currently in use:
// export const onUrlChange = (params: any, comp): void => { // used for history back button
//     console.log('url Change called', params);

//     if (!comp.model.showReceipt) {
//         if (!!params.query.step) {
//             nextStep(params.query.step, false, comp);
//         }
//         else {
//             nextStep(comp.cms.defaultCard, true, comp);
//         }
//     }
// }

export const handleNavigationTracking = () => {
	try {
		setTimeout(() => {
			if (store.state.trackingContext.lastUrl !== location.href) {
				const href = location.href.toLocaleLowerCase();
				const doTrackPage = doTrack.find((trackPage) => href.includes(trackPage));
				if (doTrackPage?.length > 0) {
					fireTrackingEvents();
				}
			}
		}, pageLoadWait);
	} catch (e) {
		console.error(e);
	}
};

const trackingFallback = () => {
	try {
		setInterval(() => {
			if (store.state.trackingContext.lastUrl !== location.href) {
				handleNavigationTracking();
			}
		}, urlPollingInterval);
	} catch (e) {
		console.error(e);
		// Too bad
	}
};

try {
	if ((window as any).navigation) {
		// chrome, Edge
		(window as any).navigation.addEventListener('navigate', () => {
			handleNavigationTracking();
		});
	} else {
		console.warn('UrlPolling enabled');
		// Firefox, safari
		trackingFallback();
	}
} catch (e) {
	console.error(e);
	// ?
	trackingFallback();
}

/***** private methods *****************/

const fireTrackingEvents = async () => {
	await trackGeneralPageView(store.state.trackingContext.lastUrl, location.href, location.pathname);
	store.state.trackingContext.lastUrl = location.href;
	await store.dispatch(FIRE_GA4_EVENTS);
};

const handleProductBasketMove = (cardName: string, comp): boolean => {
	if (STEPS_EXCLUDE_BASKET.includes(cardName as any) && comp.model.productName !== 'kurv') {
		// check if there is multiple products (Basket)
		if (comp.model.multipleProducts) {
			comp.model.readyForBasket = true;
			const basket = store.getters.getSelectableGroup('basket');
			let foundNextProduct = false;
			basket.forEach((productName: string) => {
				let match = comp.model.productName === productName;
				if (!match) {
					const activeIndex = store.getters.getActiveCalculatorInx;
					match = comp.model.productName + activeIndex === productName;
				}

				if (!match && !foundNextProduct) {
					//check if calculator is done
					const product = store.getters.getCalculator(productName);
					if (!product?.model?.readyForBasket) {
						foundNextProduct = true;
						store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, {
							fromCommonModel: false,
						});
						comp.addResetSubscription();
						const productInx = getCalculatorIndex(productName);
						if (productInx > -1) {
							store.dispatch(UPDATE_CALCULATOR_INDEX, productInx);
						}
						comp.$router.push('gotoproduct');
						return true;
					}
				}
			});
			// all products calculated - goto basket
			if (!foundNextProduct) {
				store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, {
					fromCommonModel: false,
				});
				comp.addResetSubscription();
				// comp.$router.push('kurv');
				comp.$router.push('gotoproduct');
			}
			return true;
		}
	}
	return false;
};

const isPreviousStepsValid = (cardName: string, comp): boolean => {
	const stepInx = comp.steps.indexOf(cardName);
	if (stepInx <= 0) {
		// 'none' or 1. step
		return true;
	}
	let c = stepInx - 1;
	while (c >= 0) {
		if (!comp.isValid(comp.steps[c])) {
			return false;
		}
		c--;
	}
	return true;
};

const isStepPreviousToCurrent = (cardName: string, comp): boolean => {
	if (cardName === STEP.OVERVIEW && comp.model.multipleProducts && comp.model.productName !== 'kurv') {
		return false;
	}
	return comp.steps.indexOf(cardName) < comp.steps.indexOf(comp.model.currentCardName);
};

const trackNextStep = async (cardName: string, addToHistory, comp) => {
	nextTick().then(() => {
		scrollMeTo(comp);
	});
	await trackProductStep(comp.model.productName, cardName);
	addStepToHistory(addToHistory, comp);
};

const scrollMeTo = (comp) => {
	let scrollDistance = -25;

	if (isFirstStep(comp)) {
		if (window.innerWidth < 768) {
			scrollDistance = -90;
		} else {
			scrollDistance = -112;
		}
		if (hasHeaderBadge(comp)) {
			setTimeout(() => {
				VueScrollTo.scrollTo(`.vertical-app.badge`, 300, {
					easing: 'ease-out',
					offset: scrollDistance,
				});
				// Hack to Fix scrollbar missing
				document.body.style.overflow = 'initial';
			}, comp.cms.animationTimeout);
			return;
		}
	}

	setTimeout(() => {
		VueScrollTo.scrollTo(`#${comp.model.currentCardName}`, 300, {
			easing: 'ease-out',
			offset: scrollDistance,
		});
		// Hack to Fix scrollbar missing
		document.body.style.overflow = 'initial';
	}, comp.cms.animationTimeout);
};

const isFirstStep = (comp): boolean => {
	// first step?
	return comp.contentBlocks[0].name === comp.model.currentCardName;
};

const hasHeaderBadge = (comp): boolean => {
	try {
		// first step?
		if (isFirstStep(comp)) {
			const hasBadge = !!document.querySelector('.vertical-app.badge');
			return hasBadge;
		}
		return true;
	} catch (error) {
		return true;
	}
};

export const getNextCardLinkText = (comp: any) => {
	// check if there is multiple products (Basket)
	if (comp.model.multipleProducts) {
		const basket = store.getters.getSelectableGroup('basket');
		const activeIndex = store.getters.getActiveCalculatorInx;
		let foundNextProduct = undefined;
		basket.forEach((productName) => {
			if (!foundNextProduct && comp.model.productName + activeIndex !== productName) {
				const product = store.getters.getCalculator(productName);
				if (!product?.model?.readyForBasket) {
					if (screen.width < 425) {
						productName = getShortProductName(productName);
					}
					foundNextProduct = 'Fortsæt til ' + getCalculatorNameUi(productName);
				}
			}
		});
		if (foundNextProduct) {
			return foundNextProduct;
		}
		return 'Gå til Kurv';
	} else {
		return comp.card.linkText;
	}
};
