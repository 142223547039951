import { mapState } from 'vuex';
import FamilyMemberComponent from '../../components/FamilyMemberComponent.vue';
import { FamilySettings } from '../FamilySettings';
import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import { modalWithExit, togglePopup } from '../../services/AlertService';

@Options({
	name: 'FamilyInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
	},
	components: {
		FamilyMemberComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class FamilyInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public model!: any; // datastructure for user input (store)
	public cms!: FamilySettings; // settings from CMS (store)

	public personCountList: Array<string> = [];

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
		if (this.cms.useAdultAccident) {
			this.model.familyInfo.personCount = this.model.personInfo.familyMemberCount;
			this.model.familyInfo.personCountId = this.model.personInfo.familyMemberCountId - 1;

			setTimeout(() => {
				const input = document.getElementById('name0');
				if (input) {
					input.focus();
				}
			}, this.cms.animationTimeout);
		}
		// only show valid options
		this.personCountList = [];
		this.cms.personCountList.forEach((element, inx) => {
			if (inx < this.model.personInfo.familyMemberCountId - 1) {
				this.personCountList.push(element);
			}
		});

		// TODO KLK when in basket mode, always load family first if travel or home insurance is present, to avoid too low household membership
		// This presents however the problem, when user adds family, when travel or home has been calculated - so the calculations should be invalidate
		if (this.personCountList.length === 0) {
			const modal = modalWithExit(this.cms);
			modal.title = 'Ingen børn i husstand';
			modal.content =
				'Du har oplyst at der er @personCount i jeres husstand. Ret venligst på foregående trin, antal personer i jeres husstand, så alle personer er inkluderet'.replace(
					'@personCount',
					this.model.personInfo.familyMemberCount
				);
			modal.id = 'personCountError';
			togglePopup(this.cms, modal);
		}
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public familyMemberCountSelected(result: any) {
		if (result && result.selectedValue) {
			this.model.familyInfo.personCount = result.selectedValue;
			this.model.familyInfo.personCountId = this.cms.personCountList.indexOf(this.model.familyInfo.personCount);
			nextTick(() => {
				// wait for transition to begin showing 1. name field
				setTimeout(() => {
					const input = document.getElementById('name0');
					if (input) {
						input.focus();
					}
				}, 300);
			});
			return;
		}
		this.model.familyInfo.personCount = undefined;
		this.model.familyInfo.personCountId = 0;
	}

	public get personCountLabel(): string {
		return this.cms.useAdultAccident
			? 'Hvor mange i husstanden skal forsikres'
			: 'Hvor mange børn der skal forsikres?';
	}

	/* eslint-disable */
	public getLabelWork(inx: number): string {
		if (inx === 0 && this.cms.useAdultAccident) {
			return 'Hvad arbejder du med';
		}
		let name: string = this.model.familyInfo['name' + inx];
		if (name && name.trim().length > 2) {
			name = name.trim();
			const res = name.match(/^[a-zA-Z\æøå]+/gi);
			if (res && res[0].length > 2) {
				return `Hvad arbejder ${res[0]} med`;
			}
		}
		const age = this.model.familyInfo['age' + inx];
		if (age < 18) {
			return 'Hvad arbejder dit barn med';
		}
		return 'Hvad arbejder din medbo med';
	}
	/* eslint-enable */

	public getPersonNameLabel(inx: number): string {
		if (inx === 0 && this.cms.useAdultAccident) {
			return 'Dit navn';
		}
		return this.cms.useAdultAccident ? 'Fulde navn' : 'Dit barns fulde navn';
	}

	public getPersonAgeLabel(inx: number): string {
		return this.cms.useAdultAccident ? 'alder' : 'Dit barns alder';
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}
}
