import { isExternalLink } from '@/services/environmentService';
import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
	name: 'LinkComponent',
	components: {},
	// inheritAttrs: false,
	computed: mapState<any>({
		isEditable: (state) => state.epiContext.isEditable,
	}),
	props: {
		to: { type: String, required: false },
		target: String,
		externalIcon: { type: Boolean, default: false },
		smallIcon: { type: Boolean, default: false },
		xSmallIcon: { type: Boolean, default: false },
		alternateIcon: { type: Boolean, default: false },
	},
})
export default class LinkComponent extends Vue {
	to: string;
	isEditable!: boolean;
	target: string;
	externalIcon: boolean;
	smallIcon: boolean;
	xSmallIcon: boolean;
	alternateIcon: boolean;
}
