import { STEP } from '../services/UiStepService';

// list and order of steps
export const STEPS: Array<string> = [STEP.OVERVIEW, STEP.CONTACT_INFORMATION, STEP.PAYMENT, STEP.ORDER];

export class Model {
	public static model = {
		campaign: {
			ID: undefined,
			valid: false,
			init: false,
			hasContentPage: false,
			subtitle: undefined,
			uniqueKey: undefined,
			discount: undefined,
			productStepDiscount: undefined,
		},
		personInfo: {
			subtitle: undefined,
			customerAge: undefined,
			zipCode: undefined,
			zipName: undefined,
			familyMemberCount: undefined,
			familyMemberCountId: undefined,
			almbrandCustomer: undefined,
			existingAlmBrandProducts: [],
		},
		choosePackage: {
			monthYear: undefined,
		},
		contact_information: {
			subtitle: undefined,
			name: undefined,
			email: undefined,
			phone: undefined,
			cpr: undefined,
			customerNo: undefined,
			accept: false,
			rki: undefined,
			skader: undefined,
		},
		overview: {
			subtitle: undefined,
		},
		payment: {
			subtitle: undefined,
			regNo: undefined,
			kontoNo: undefined,
			userSelected: false,
		},
		pristineStep: new Map([
			[STEP.OVERVIEW.valueOf(), true],
			[STEP.CONTACT_INFORMATION.valueOf(), true],
			[STEP.PAYMENT.valueOf(), true],
		]),
		calculation: {
			discount: undefined,
		},
		productName: 'kurv',
		showReceipt: false,
		currentCardName: 'none',
		updateAll: 'updateAll',
	};
}
