import { mapState } from 'vuex';
import CarSearchComponent from '../../components/CarSearchComponent.vue';
import CarCalculator from '../CarCalculator';
import { CarSettings } from '../CarSettings';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import { modalWithExit, togglePopup } from '../../services/AlertService';

const searchIcon = 'input__field__search-icon';
const searchingIcon = 'input__field--searching';
const validIcon = 'input__field--valid';

@Options({
	name: 'CarInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
		calculator: CarCalculator, // for licenseplateSearch
	},
	components: {
		CarSearchComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class CarInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	calculator: CarCalculator; // for licenseplateSearch
	isValid: boolean; // wether card is complete

	public model!: any; // datastructure for user input (store)
	public cms!: CarSettings; // settings from CMS (store)

	public lastSearchLicensePlate: string;
	public showLicensePlateStatus = false;
	public updateLicenseplate = 'updateLicenseplate';
	public updateSearchBrandModel = 'updateSearchBrandModel';
	public inputClassOverride = searchIcon;

	public mounted() {
		if (this.model.carInfo.variantId) {
			this.inputClassOverride = validIcon;
		}

		setTimeout(() => {
			if (this.model.campaign.valid && !this.model.campaign.init && this.model.carInfo.searchBrandModel) {
				const input = document.getElementById('#brand'); // TODO KLK ??? #brand
				if (input) {
					input.focus();
				}
			} else if (!this.model.carInfo.variantId) {
				const input = document.getElementById('licenseplate');
				if (input) {
					input.focus();
				}
			}
			this.model.campaign.init = true;
		}, this.cms.animationTimeout + 500);
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public get showCarInfoBox() {
		return this.showLicensePlateStatus || this.model.carInfo.displayText;
	}

	public checkboxEvent(evt) {
		this.model.carInfo.searchBrandModel = evt.checked;
		// reset carSearch when toggle search method
		this.updateLicenseplate += 1;
		this.inputClassOverride = searchIcon;
		this.model.carInfo.licenseplate = undefined;
		this.model.carInfo.displayText = undefined;
		this.model.carInfo.car = undefined;
		this.model.carInfo.variantId = undefined;
		this.model.carInfo.special = false;
		this.model.carInfo.subtitle = undefined;
		// reset pristine
		this.model.pristineStep.set(this.card.name, true);
		// toggle help txt for licenseplatesearch
		this.showLicensePlateStatus = false;
		this.lastSearchLicensePlate = undefined;
		if (!this.model.carInfo.searchBrandModel) {
			setTimeout(() => {
				const input = document.getElementById('licenseplate');
				if (input) {
					input.focus();
				}
			}, 200);
		}
	}

	public resetCar(value, force = false) {
		if (!force && !this.model.carInfo.searchBrandModel && this.lastSearchLicensePlate !== value) {
			return;
		}
		this.model.carInfo.subtitle = undefined;
		this.model.carInfo.car = 'bil';
		this.model.carInfo.year = undefined;
		this.model.carInfo.yearlyMileage = this.model.carInfo.yearlyMileage
			? this.model.carInfo.yearlyMileage
			: this.cms.kmForbrug[2]; // op til 15.000 km;
		this.model.carInfo.licenseplate = undefined;
		this.model.carInfo.carWeight = 0;
		this.model.carInfo.kid = 0;
		this.model.carInfo.fuelType = undefined;
		this.model.carInfo.isElectricHybrid = false;
		this.model.carInfo.variantId = undefined;
		this.model.carInfo.special = undefined;
		this.model.carInfo.variantName = undefined;
		this.model.carInfo.vehicleGroup = undefined;
		this.model.carInfo.model = undefined;
		this.model.carInfo.brand = undefined;
		this.model.carInfo.displayText = undefined;
		this.model.carInfo.checkForSpecialCar = false;
		this.model.carInfo.specialDetected = false;
		this.inputClassOverride = searchIcon;
	}
	public carSelected(result) {
		this.mapCar(result.car);
	}

	public async licenseplateChanged(value) {
		try {
			if (!value || value === '') {
				if (this.model.carInfo.searchBrandModel) {
					this.model.carInfo.searchBrandModel = false;
					this.updateSearchBrandModel += 1;
				}
			}

			if (this.lastSearchLicensePlate === value) {
				return;
			}
			if (value?.length < 2) {
				this.lastSearchLicensePlate = '';
				this.resetCar(value, true);
				return;
			}
			const res = value.match(/^[a-z0-9\-]+/gi);
			if (!res || res[0] !== value) {
				this.lastSearchLicensePlate = '';
				this.resetCar(value, true);
				return;
			}

			this.lastSearchLicensePlate = value;

			this.model.pristineStep.set(this.card.name, true);
			if (value && value.length > 0) {
				this.inputClassOverride = searchingIcon; // 'input--searching';
				let okTyping = false;

				const response = await this.calculator.searchLicensePlate(value);
				if (response?.data?.ignore) {
					return;
				}

				if (response?.status === 400) {
					const problem = response.data?.car_problem[0]?.id;
					if (
						problem === '33' ||
						problem === '10' ||
						problem === 'registration_number' ||
						problem === 'error_message'
					) {
						// ok, customer is probably typing
						okTyping = true;
					}
					// motor register problem (DMR service)
					if (
						value?.length === 7 &&
						response.data?.detail?.toLocaleLowerCase().includes('en fejl i kaldet til motorregisteret.')
					) {
						okTyping = false;
						const modal = modalWithExit(this.cms);
						modal.id = this.cms.popupDmrId;
						modal.track = this.cms.popupDmrTrack;
						modal.trackToken = this.cms.popupDmrTrackToken;
						modal.errorType = this.cms.popupDmrId;
						modal.title = this.cms.popupDmrTitle;
						modal.content = this.cms.popupDmrContent;
						togglePopup(this.cms, modal);
						this.resetCar(value);
						return;
					}
				}
				if (!response || (!okTyping && response?.status !== 200)) {
					const modal = modalWithExit(this.cms);
					modal.title = this.cms.licensePlateErrorTitle;
					modal.content = this.cms.licensePlateErrorContent;
					modal.track = true;
					modal.id = 'licenseplateSearchError';
					modal.trackToken = 'car licenseplateSearch';
					togglePopup(this.cms, modal);
					this.inputClassOverride = searchIcon;
				}
				if (response?.data[0] && response.data.length > 0) {
					if (!this.model.carInfo.licenseplate) {
						this.model.carInfo.licenseplate = value;
					}
					this.mapCar(response.data[0]);
					this.showLicensePlateStatus = false;
					return;
				} else {
					this.showLicensePlateStatus = true;
				}
			}
			this.resetCar(value);
		} catch (error) {
			console.error(error);
		}
	}

	private async mapCar(car) {
		this.model.carInfo.brand = car.brand;
		this.model.carInfo.carWeight = car.details?.weight;
		this.model.carInfo.kid = car.kid;
		this.model.carInfo.fuelType = car.details?.fuel_type;
		this.model.carInfo.isElectricHybrid = this.isElectricHybrid(car);
		(this.model.carInfo.model = car.model), (this.model.carInfo.variantId = car.variant_id);
		this.model.carInfo.special = car.special;
		this.model.carInfo.variantName = car.variant;
		this.model.carInfo.vehicleGroup = car.group;
		this.model.carInfo.year = car.details?.year;
		this.model.carInfo.licenseplate = this.model.carInfo.licenseplate
			? this.model.carInfo.licenseplate.toUpperCase()
			: undefined;
		const lPlate = this.model.carInfo.licenseplate ? ' (' + this.model.carInfo.licenseplate + ')' : '';
		this.model.carInfo.car = car.brand + ' ' + car.model + lPlate;
		this.model.carInfo.subtitle = this.model.carInfo.car;
		this.model.carInfo.displayText =
			this.model.carInfo.car + ' ' + this.model.carInfo.year + ' ' + this.model.carInfo.variantName;
		this.model.carInfo.checkForSpecialCar = false;
		this.model.carInfo.specialDetected = false;
		if (this.model.carInfo.licenseplate) {
			this.inputClassOverride = validIcon;
		} else {
			this.inputClassOverride = searchIcon;
		}
	}

	private isElectricHybrid(car): boolean {
		if (car?.fuelType) {
			const fuelType = car.fuelType.trim().toLocaleLowerCase();
			return this.cms.electricFuelTypes.includes(fuelType);
		}
		return false;
	}
	public carSearchProblem(car) {
		const modal = modalWithExit(this.cms);
		modal.title = car;
		modal.content =
			'Vi kan desværre ikke finde den bil du søger. Du er velkommen til at kontakte os, så vi kan hjælpe dig med et tilbud.';
		modal.id = 'carSearchProblem';
		modal.trackToken = 'carSearch Problem';
		togglePopup(this.cms, modal);
	}
	public carSearchError() {
		const modal = modalWithExit(this.cms);

		modal.title = 'Problem med bilsøgning';
		modal.content =
			'Der er desværre i øjeblikket problemer med bilsøgning. Prøv igen om et øjeblik eller kontakt os.';
		modal.btnSecondLabel = 'Ok';
		modal.track = true;
		modal.id = 'carSearchError';
		modal.trackToken = 'carSearch Error';
		togglePopup(this.cms, modal);
	}

	public selectYearlyMilage(evt) {
		this.model.carInfo.yearlyMileage = evt.selectedValue;
	}

	public nextStep() {
		if (!this.model.carInfo.licenseplate && !this.model.carInfo.searchBrandModel) {
			this.inputClassOverride = undefined;
		}

		this.$emit('nextStep', this.card.routePath);
	}
}
