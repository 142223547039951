import { information } from '@/appinsights/logging/ApplicationInsightLog';
import { UPDATE_AUTH } from '@/store/modules/authenticationContext';
import store from '@/store/store';
import idleTimeout from 'idle-timeout';

/***** private methods *****************/
/**
 * @returns true if open in App, else false
 */
export const isApp = (): boolean => {
	// (<any>window).nsWebViewBridge = { emit : (event: unknown, val: unknown) => {
	//     console.log(event, val);

	// }}
	if (window.location.href.indexOf('client=app') > -1) {
		return true;
	}

	if ((<any>window).nsWebViewBridge !== undefined) {
		return true;
	}
	return false;
};
export const isWeb = (): boolean => {
	return !isApp();
};

const isDevelop_ = () => {
	return (
		location.href.startsWith('https://localhost') ||
		location.href.startsWith('https://alpha.') ||
		location.href.startsWith('https://alpha-') ||
		location.href.startsWith('https://sit-') ||
		location.href.startsWith('https://prep.') ||
		location.href.startsWith('https://prep-')
	);
};

const isLocalhost_ = () => {
	return location.href.startsWith('https://localhost');
};

/***** public *****************/
export const isExternalLink = (routePath): boolean => {
	return routePath && (routePath.startsWith('//') || routePath.toLocaleLowerCase().startsWith('https'));
};

/**
 * Sends a message to the app via gotMessage
 * @param message An object containing the message: { 'MAIN_TITLE': 'title'}
 *
 */
export const AppSendMessage = (message) => {
	if (message && isApp()) {
		(<any>window).nsWebViewBridge?.emit('gotMessage', message);
		if (!(<any>window).nsWebViewBridge) {
			information('App_message', message);
		}
	}
};

export const browserIe11OrOlder = () => {
	let bool = false;
	if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
		bool = true;
	}
	return bool;
};

export const handleIdleLogout = () => {
	if (isWeb()) {
		let time = 1000 * 60 * 30;
		if (store.state.idleTimeOutMinutes) {
			time = 1000 * 60 * store.state.idleTimeOutMinutes;
		}
		idleTimeout(
			() => {
				window.location.href = store.state.logOutUrl;
			},
			{
				timeout: time,
				loop: true,
				element: <any>document,
			}
		);
	}
};

export const setPageMetaData = (model: any) => {
	try {
		if (!model) {
			return;
		}
		document.title = model.metaTitle || document.title;

		const metaData = [
			{
				type: 'description',
				value: model.metaDescription || '',
				property: 'name',
			},
			{ type: 'keywords', value: model.metaKeywords || '', property: 'name' },
			//   { type: 'og:image', value: model.ogImage && model.ogImage.url ? model.ogImage.url : '', property: 'property' },
			//   { type: 'og:description', value: model.ogDescription || '', property: 'property' },
			//   { type: 'og:title', value: model.ogTitle || '', property: 'property' }
		];
		metaData.forEach((meta) => {
			const metaTag = document.querySelector(`meta[${meta.property}="${meta.type}"]`);

			if (!metaTag) {
				const tag = document.createElement('meta');
				tag.setAttribute(meta.property, meta.type);
				tag.setAttribute('content', meta.value);
				document.head.appendChild(tag);
			} else {
				metaTag.setAttribute('content', meta.value);
			}
		});
	} catch (e) {
		console.error(e);
		// too bad
	}
};

export const setSoMe = () => {
	try {
		const metaData = [
			{ type: 'og:type', value: 'website', property: 'name' },
			{ type: 'og.url', value: location.origin, property: 'name' },
			{
				type: 'og:image',
				value: '/alm.-brand/alm.-brand-media/images-jpg/private/insurance/couple-sofa.jpg',
				property: 'property',
			},
			{ type: 'og:image:width', value: '1716', property: 'property' },
			{ type: 'og:image:height', value: '1100', property: 'property' },
			{ type: 'og:image:alt', value: 'Alm. Brand', property: 'property' },
			{
				type: 'og:description',
				value: 'Forsikringer til dig og dine behov. Vi d&#xE6;kker familier, virksomheder og landbrug i alle st&#xF8;rrelser. V&#xE6;lg dine d&#xE6;kninger og beregn din pris online.',
				property: 'property',
			},
			{
				type: 'og:title',
				value: 'Forsikringer, der passer til dig | Alm. Brand',
				property: 'property',
			},
			{ type: 'og:site_name', value: 'Alm. Brand', property: 'property' },
		];
		metaData.forEach((meta) => {
			const metaTag = document.querySelector(`meta[${meta.property}="${meta.type}"]`);

			if (!metaTag) {
				const tag = document.createElement('meta');
				tag.setAttribute(meta.property, meta.type);
				tag.setAttribute('content', meta.value);
				document.head.appendChild(tag);
				// console.log('creating metaTag', tag);
			} else {
				metaTag.setAttribute('content', meta.value);
			}
		});
	} catch (e) {
		console.error(e);
		// too bad
	}

	// <meta property="og:title" content="Forsikringer, der passer til dig | Alm. Brand" />
	// <meta property="og:description" content="Forsikringer til dig og dine behov. Vi d&#xE6;kker familier, virksomheder og landbrug i alle st&#xF8;rrelser. V&#xE6;lg dine d&#xE6;kninger og beregn din pris online." />
	// <meta property="og:type" content="website" />
	// <meta property="og:url" content="https://www.almbrand.dk/" />
	// <meta property="og:image" content="/alm.-brand/alm.-brand-media/images-jpg/private/insurance/couple-sofa.jpg" />

	// <meta property="og:image:width" content="1716" />
	// <meta property="og:image:height" content="1100" />

	// <meta property="og:image:alt" content="Alm. Brand" />
	// <meta property="og:site_name" content="Alm. Brand" />
};

export const setVersion = () => {
	try {
		if (isLocalHost) {
			store.state.uiVersion = 'localhost' + ' ver. 0.23.0_rubbish';
			return;
		}
		const scripts = document.scripts;
		let stop = false;
		for (const item of scripts) {
			if (!stop && item.src.includes('js/app.js') && item.src.includes(location.host)) {
				const src = item.src.split('/');
				src.forEach((element) => {
					if (!Number.isNaN(parseFloat(element))) {
						const appMode = getDevice();
						store.state.uiVersion = appMode + ' ver. ' + element;
						stop = true;
					}
				});
			}
		}
	} catch (e) {
		// Too bad
	} finally {
		store.state.versionInit = true;
	}
};

export const getDevice = (): string => {
	try {
		let appMode = isApp() ? 'App' : 'Web';
		if (appMode === 'Web') {
			// navigator.userAgentData.mobile is experimental
			if ((navigator as any)?.userAgentData?.mobile) {
				appMode = 'Mobile';
			} else if (navigator?.userAgent?.match(/(iPod|iPhone|iPad)/)) {
				appMode = 'Mobile';
			}
		}
		return appMode;
	} catch (e) {
		return 'Unknown platform';
	}
};
export const setMenu = async () => {
	try {
		if (isApp()) {
			// don't show menu in APP-mode
			return;
		}
		if (!store.state.authenticationContext.isLoaded) {
			await store.dispatch(UPDATE_AUTH);
		}

		if (store.state.authenticationContext.authenticated) {
			// show menu
			store.state.menuModel = store.state.frontendConfiguration.menu.menuPoints;
		}
	} catch (e) {
		console.error(e);
		// Too bad
	}
};

// handle "eternal" spinning
export const spinnerThreshold = async (model: { stopSpinner: boolean }, timeout: number) => {
	model.stopSpinner = true;
	setTimeout(() => {
		if (model.stopSpinner) {
			store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;
		}
	}, timeout);
};

export const logToUi = (msg: unknown, doLog = false) => {
	if (!isDevelop || !doLog) {
		return;
	}
	const logger = document.getElementById('logger');
	if (!logger) {
		const div = document.createElement('div');
		// set style
		div.style.border = '2px solid red';
		div.id = 'logger';
		document.body.prepend(div);
	}
	const logElem = document.getElementById('logger');

	if (typeof msg == 'object') {
		logElem.innerHTML += (JSON && JSON.stringify ? JSON.stringify(msg) : msg) + '<br />';
	} else {
		logElem.innerHTML += msg + '<br />';
	}
};
// export const isApp = isApp_();
// export const isWeb = !isApp;
export const isDevelop = isDevelop_();
export const isLocalHost = isLocalhost_();
