import { isDevelop } from '@/services/environmentService';
import Coverage from '../components/coverageComponent/Coverage';
import { STEP } from '../services/UiStepService';
// list and order of steps
export const STEPS: Array<string> = [
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class Model {
	public static model = {
		campaign: {
			ID: undefined,
			valid: false,
			init: false,
			hasContentPage: false,
			subtitle: undefined,
			uniqueKey: undefined,
			discount: undefined,
			productStepDiscount: undefined,
		},
		personInfo: {
			subtitle: undefined,
			customerAge: undefined,
			address: undefined,
			zipCode: undefined,
			zipName: undefined,
			streetName: undefined,
			houseNr: undefined,
			floorNr: undefined,
			doorNr: undefined,
			kvhxValue: undefined,
			almbrandCustomer: undefined,
			existingAlmBrandProducts: [],
			holidayAddress: {
				address: undefined,
				zipCode: undefined,
				zipName: undefined,
				streetName: undefined,
				houseNr: undefined,
				floorNr: undefined,
				doorNr: undefined,
				kvhxValue: undefined,
			},
		},
		choosePackage: {
			subtitle: undefined,
			ownRiskId: undefined,
			selectedPackage: undefined,
			monthYear: undefined, //this.monthYearArray[0].value,
		},
		contact_information: {
			subtitle: undefined,
			name: undefined,
			email: undefined,
			phone: undefined,
			cpr: undefined,
			customerNo: undefined,
			accept: false,
		},
		overview: {
			subtitle: undefined,
			highlights: undefined,
			details: undefined,
		},
		additionalInfo: {
			subtitle: undefined,
			existInsurance: undefined,
			existingInsurance: undefined,
			existingInsuranceId: undefined,
			validNow: undefined,
			validFromDate: undefined,
			skader: undefined,
			rki: undefined,
		},
		payment: {
			subtitle: undefined,
			regNo: undefined,
			kontoNo: undefined,
			userSelected: false,
		},
		pristineStep: new Map([
			[STEP.PERSON_INFO.valueOf(), true],
			[STEP.PACKAGE.valueOf(), true],
			[STEP.ADDITIONAL_INFO.valueOf(), true],
			[STEP.OVERVIEW.valueOf(), true],
			[STEP.CONTACT_INFORMATION.valueOf(), true],
			[STEP.PAYMENT.valueOf(), true],
		]),
		productName: undefined,
		ownRiskOptions: [] as Array<number>,
		ownRiskLabels: [] as Array<string>,
		updatedExcessListFromProduct: false,
		calculation: {
			isCalculated: false,
			updatePrices: ['pr1', 'pr2', 'pr3'],
			excessIdDefault: undefined,
			packageIds: undefined,
			packages: undefined,
			abCalc: {
				excesses: [],
				packs: new Map<number, Object>(),
			},
			allCoverages: new Map<number, Map<number, Array<Coverage>>>(),
			discount: undefined,
			discountDisplay: undefined,
			addressSupplement: 1,
		},
		showReceipt: false,
		currentCardName: 'none',
		updateAll: 'updateAll',
		readyForBasket: false,
		multipleProducts: false,
		showExistingAlmBrandProducts: true,
	};

	public static mock() {
		if (isDevelop) {
			Object.assign(Model.model.personInfo, {
				almbrandCustomer: 'nej',
				// almbrandCustomer:"ja",
				// existingAlmBrandProducts:['Indboforsikring', 'Husforsikring'],
				customerAge: '45',
				existingAlmBrandProducts: [],
				address: 'Adolphsvej 19, 2820 Gentofte',
				houseNr: '19',
				streetName: 'Adolphsvej',
				subtitle: '45 år, Adolphsvej 19, 2820 Gentofte',
				zipCode: '2820',
				zipName: 'Gentofte',
				holidayAddress: {
					address: 'Bakkelyvej 62, Overby Lyng, 4583 Sjællands Odde',
					zipCode: '4583',
					zipName: 'Sjællands Odde',
					streetName: 'Bakkelyvej',
					houseNr: '62',
					floorNr: undefined,
					doorNr: undefined,
					kvhxValue: undefined,
				},
			});

			Object.assign(Model.model.additionalInfo, {
				existInsurance: 'nej',
				validNow: 'ja',
				skader: '0',
				rki: 'nej',
			});

			Object.assign(Model.model.contact_information, {
				accept: true,
				cpr: '2342342323',
				customerNo: undefined,
				email: 'abklku@almbrand.dk',
				name: 'Test Testesen',
				phone: '11111111',
				subtitle: 'Test Testesen',
			});
			// Object.assign(Model.model.payment, {
			//     kontoNo:'123456',
			//     regNo:'1233',
			// });
		}
	}
}
