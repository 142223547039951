import { RESET_SELECTABLE } from '@/store/modules/selectableContext';
import { addStepToHistory } from './NavigationService';
import { isOpenHours, setOpenTxtOnReceipt } from './AvailabilityService';
import store from '@/store/store';
import { getCoverageNames, resetCampaignStorage, stripProductIndex } from './ProductService';
import { capitalizeFirstLetter } from './UiStepService';
import { sendMailNoLoginLead, sendMailToOssNoLogin, sendMailToTeamNoLogin } from '@/services/emailService';
import { CalculatorComponent } from '../BuyInsuranceComponent';
import { sendToSalesForce } from '@/services/SalesForceLeadService';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { getDevice } from '@/services/environmentService';
import { ModalSubmitToggle } from './AlertService';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

// ORDER By Email ********************************
const Formatter: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
} as any);

// clean htmlString
const endTags = new RegExp(/((<\/)\w+(>))/gi); // all end tags
const allTags = new RegExp(/<[^>]*>/gi); // all tags
const softHtmlTags = new RegExp(/<a.*?>|<\/a>|<b.*?>|<\/b>|<ul.*?>|<\/ul>|<i.*?>|<\/i>/gi); // a, b, i, ul
const newLineSymbol = '¤';

// Sections
const sections: Array<string> = [
	capitalizeFirstLetter(InsurancesProductEnum.BIL_FORSIKRING),
	capitalizeFirstLetter(InsurancesProductEnum.BIL_FORSIKRING) + 1,
	capitalizeFirstLetter(InsurancesProductEnum.BIL_FORSIKRING) + 2,
	capitalizeFirstLetter(InsurancesProductEnum.INDBO_FORSIKRING),
	capitalizeFirstLetter(InsurancesProductEnum.REJSE_FORSIKRING),
	capitalizeFirstLetter(InsurancesProductEnum.HUS_FORSIKRING),
	capitalizeFirstLetter(InsurancesProductEnum.FRITIDSHUS_FORSIKRING),
	capitalizeFirstLetter(InsurancesProductEnum.ULYKKES_FORSIKRING),
	capitalizeFirstLetter(InsurancesProductEnum.ULYKKES_FORSIKRING) + 1,
	capitalizeFirstLetter(InsurancesProductEnum.ULYKKES_FORSIKRING) + 2,
	capitalizeFirstLetter(InsurancesProductEnum.ULYKKES_FORSIKRING) + 3,
	capitalizeFirstLetter(InsurancesProductEnum.ULYKKES_FORSIKRING) + 4,
	capitalizeFirstLetter(InsurancesProductEnum.ULYKKES_FORSIKRING) + 5,
	capitalizeFirstLetter(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING),
	capitalizeFirstLetter(InsurancesProductEnum.HUNDE_FORSIKRING),
	capitalizeFirstLetter(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING),
	'Ønsket forsikring',
	'Person',
	'Betaling',
	'Bil oplysninger',
];

export const submitOrder = async (desc: string, tracking: string, product: CalculatorComponent) => {
	desc = `<h2>Kunde ønsker en ny ${product.model.productName}</h2>${desc}`;
	desc += getBrandProp();
	if (product.model.campaign.valid) {
		const id = product.model.campaign.ID ? product.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
		if (product.model.campaign.uniqueKey) {
			desc += addProp(`${id} unik ID`, product.model.campaign.uniqueKey);
		}
		if (product.model.campaign.description) {
			desc += addProp(`Kampagne beskrivelse`, product.model.campaign.description);
		}
		if (product.model.campaign.groupId > 0) {
			desc += addProp(`Kampagne Gruppe kode`, product.model.campaign.groupId);
		}
		if (product.model.campaign.discount > 0) {
			desc += addProp(`Kampagne rabat inkluderet i pris`, product.model.campaign.discountDisplay);
		}
		if (product.model.campaign.tiaDiscount > 0) {
			desc += addProp(
				`Kampagne rabat fra TIA inkluderet i pris`,
				Formatter.format(100 * product.model.campaign.tiaDiscount) + ' %'
			);
		}
		if (product.model.campaign.splash) {
			desc += addProp(`Kampagne specifik`, product.model.campaign.splash);
		}
	}
	const params: Map<string, string> = product.buildDescriptionForEmail(desc);

	params.set('subject', `Kunde ønsker ${product.model.productName}`);
	params.set('name', product.model.contact_information.name);
	params.set('cprNo', product.model.contact_information.cpr);
	params.set('email', product.model.contact_information.email);
	params.set('phoneNo', product.model.contact_information.phone);

	const calculation = product.model.choosePackage.selectedPackage;
	const monthly = product.model.choosePackage.monthYear === 'M';

	const totalPrice = monthly ? calculation.totalPrice : calculation.yearlyPriceTotal;

	const monthlyYearlyDisplay = monthly ? ' kr. Månedligt' : ' kr. Årligt';

	params.set('products', capitalizeFirstLetter(product.model.productName));
	params.set(
		product.model.productName.replace('ø', 'oe') + '0',
		capitalizeFirstLetter(product.model.productName) + ', ' + (Formatter.format(totalPrice) + monthlyYearlyDisplay)
	);
	params.set('isOpenSingle', isOpenHours(product.cms.open) + '');
	params.set('uncalculatedProducts', 'false');

	params.set('price', totalPrice + '');
	params.set('priceFormatted', '0');
	params.set('monthlyPayment', monthly + '');
	params.set('multipleInsurances', 'false');

	const startTime = new Date().getTime();
	// console.log('sendMailToOssNoLogin', params);

	let sendToSalesForceDone = false;
	if (product.cms.sendProposalToSalesForce) {
		try {
			await sendToSalesForce(
				calculation.yearlyPriceTotal,
				product.cms.shortTitle,
				params.get('description'),
				product
			);
			sendToSalesForceDone = true;
		} catch (e) {
			console.error(e);
			sendErrorMailToTeam(e, product.model);
		}
	}

	// TODO KLK - always send email - used for customer receipt
	await sendMailToOssNoLogin(params);
	// if (!sendToSalesForceDone || product.cms.sendProposalToEmail) {
	//     // always try email if offer not posted to salesForce
	//     await sendMailToOssNoLogin(params);
	// }

	if (product.cms.mailConfirmationToTeam) {
		await sendMailToTeam(product.model);
	}

	await store.dispatch(RESET_SELECTABLE, 'basket');
	product.addResetSubscription();
	resetCampaignStorage(product.model);

	addStepToHistory(true, product);

	const mailDuration = new Date().getTime() - startTime;
	const fakeTimeout = product.cms.animationTimeoutBuy - mailDuration;
	setTimeout(
		() => {
			setOpenTxtOnReceipt(false, false, product);
			product.model.showReceipt = true;
			store.state.showSpinner = false;
			window.scrollTo(0, 0);
			product.model.currentCardName = 'receipt';
		},
		fakeTimeout < 0 ? 100 : fakeTimeout
	);

	// if stuff hangs
	setTimeout(() => {
		if (store.state.showSpinner) {
			store.state.showSpinner = false;
		}
	}, product.cms.calculationSpinnerTimeout);
};

export const getBrandProp = (): string => {
	const brand = store.getters.getThemeContext ? store.getters.getThemeContext : 'Almbrand';
	return addProp(`Brand`, capitalizeFirstLetter(brand));
};

export const sendMailToTeam = async (model) => {
	try {
		const now: Date = new Date();
		let data = `<h2>Kunde ønsker en ny ${model.productName}</h2>`;
		data += getBrandProp();
		data += addProp(
			'Email sendt',
			`${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${
				now.getMonth() + 1
			}-${now.getFullYear()}`
		);
		data += addProp('Tlf', model.contact_information.phone);
		data += addProp('Email', model.contact_information.email);
		data += addProp('KundeNr', model.contact_information.customerNo);
		const params: Map<string, string> = new Map();
		params.set('subject', `Online salg af ${model.productName} - ${getDevice()}`);
		params.set('description', data);
		return await sendMailToTeamNoLogin(params);
	} catch (e) {
		exception(e, 'sendMailToTeam');
		// too bad
	}
	return;
};

export const sendErrorMailToTeam = async (err, model) => {
	try {
		const now: Date = new Date();
		const e: Error = <Error>err;
		let data = `<h2>Kunde ønsker en ny ${model?.productName}</h2>`;
		data += addProp(
			'Email sendt',
			`${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${
				now.getMonth() + 1
			}-${now.getFullYear()}`
		);
		data += addProp('Tlf', model?.contact_information?.phone);
		data += addProp('Email', model?.contact_information?.email);
		data += addProp('KundeNr', model?.contact_information?.customerNo);
		data += addProp('Error msg', e?.message);
		data += addProp('Error name', e?.name);
		data += addProp('Error stack', e?.stack);

		const params: Map<string, string> = new Map();
		params.set('subject', `ERROR Online salg af ${model?.productName}`);
		params.set('description', data);

		return await sendMailToTeamNoLogin(params);
	} catch (e) {
		exception(e, 'sendErrorMailToTeam');
		// too bad
	}
	return;
};

export const sendMailLead = async () => {
	try {
		const products = store.getters.getSelectableGroup('basket');
		let contact_information = store.getters.getCalculatorCommonModel?.contact_information;
		if (!contact_information?.phone && !contact_information?.email) {
			contact_information = store.getters.getActiveCalculator?.model?.contact_information;
			if (!contact_information?.phone && contact_information?.email) {
				// console.log('no contact_information', store.getters.getActiveCalculator?.model?.contact_information, store.getters.getCalculatorCommonModel);
				return null;
			}
		}
		if (!contact_information.accept) {
			return;
		}
		const now: Date = new Date();
		let prods = 'Ingen valgte produkter';
		if (products?.length > 0) {
			prods = stripProductIndex(products.join(', ').trim());
		}

		let data = `<h2>Mulig lead - kunde har valgt online : ${prods}</h2>`;
		data += addProp(
			'Email sendt',
			`${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${
				now.getMonth() + 1
			}-${now.getFullYear()}`
		);
		data += addProp('Tlf', contact_information.phone);
		data += addProp('Email', contact_information.email);
		if (contact_information.customerNo) {
			data += addProp('Kunde logget ind', 'Ja');
			data += addProp('KundeNr', contact_information.customerNo);
		} else {
			data += addProp('Kunde logget ind', 'Nej');
		}
		data += addProp('Accepteret betingelser', contact_information.accept ? 'Ja' : 'Nej');
		const params: Map<string, string> = new Map();
		params.set('subject', `Mulig lead - online salg af ${prods}`);
		params.set('description', data);

		return await sendMailNoLoginLead(params);
	} catch (e) {
		exception(e, 'sendMailLead');
		// too bad
	}
	return;
};

export const buildExistingProductsDescription = (model, cms): string => {
	let description = addProp('Eksisterende kunde', model.personInfo.almbrandCustomer);
	if (model.personInfo.almbrandCustomer === 'ja') {
		const existingProducts = getExistingProductList(model, cms);
		description += addProp(
			'Nuværende forsikringer',
			existingProducts.length > 0 ? existingProducts.join(', ') : 'Ingen'
		);
	}
	if (model.calculation.discount && model.calculation.discount < 1) {
		// Børnefamilie giver 5% Pluskunde rabat
		description += addProp('PlusKunde rabat inkluderet i pris', model.calculation.discountDisplay);
	}
	return description;
};

export const addProp = (label: string, val = ''): string => {
	return `${label}: <b>${val}</b><br>`;
};

export const buildPaymentInfo = (model, pack, calculationType): string => {
	let desc = '';
	desc += addProp('Betaling', model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
	desc += addProp(
		'Samlet pris',
		model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
	);
	desc += addProp('Beregningstype', calculationType);
	desc += addProp('Dækninger', getCoverageNames(pack));

	return desc;
};

export const buildCreditCardInfo = (model, cms): string => {
	let desc = '';
	if (cms.skipAccountInfo) {
		desc += '<h2>Betalings information er fjernet fra beregner</h2>';
		desc += addProp('Reg. nr.', 'NA');
		desc += addProp('Konto nr.', 'NA');
	} else {
		desc += '<h2>Betaling</h2>';
		desc += addProp('Reg. nr.', model.payment.regNo);
		desc += addProp('Konto nr.', model.payment.kontoNo);
	}

	return desc;
};

/**
 *
 * @param htmlString
 * @param outputNewLine text for replacing newline - default '\n'
 * @returns String with removed htmltags and newlines replaced by outputNewLine
 */
export const removeHtmlTags = (htmlString: string, outputNewLine = '\n'): string => {
	let htmlCleanedString = htmlString.replace(/<br>|<br\/>/g, newLineSymbol);

	htmlCleanedString = htmlCleanedString.replace(softHtmlTags, '');

	htmlCleanedString = htmlCleanedString.replace(endTags, newLineSymbol);

	htmlCleanedString = htmlCleanedString.replace(allTags, '');

	htmlCleanedString = htmlCleanedString.replace(/¤+/g, outputNewLine);

	htmlCleanedString = addNewLinesToSections(htmlCleanedString, outputNewLine);

	return htmlCleanedString;
};

/***** private methods *****************/
const getExistingProductList = (model, cms): Array<string> => {
	// check if "no products" selected
	return model.personInfo.existingAlmBrandProducts.includes(cms.discountProductNone)
		? []
		: model.personInfo.existingAlmBrandProducts;
};

const addNewLinesToSections = (htmlCleanedString: string, outputNewLine: string): string => {
	const potentialSections: Array<string> = [];
	sections.forEach((element) => {
		if (htmlCleanedString.match(new RegExp(element, 'ig'))) {
			potentialSections.push(outputNewLine + element + outputNewLine);
		}
	});

	// hardcoded here since it will mess up optimization
	potentialSections.push(outputNewLine + 'Forsikring' + outputNewLine);

	// børnefamilie:
	// Indbo
	// børneulykke

	potentialSections.forEach((name) => {
		htmlCleanedString = htmlCleanedString.replace(new RegExp(name, 'ig'), outputNewLine + name);
	});
	return htmlCleanedString;
};

export const buildHeaderHighlights = (model, isBasketModel = false): { desc: string; tracking: string } => {
	let desc = '';
	let tracking = '';
	if (model.contact_information.customerNo) {
		const device = getDevice();
		desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
		tracking += '/existingCustomer';
	}
	if (model.campaign.valid) {
		const id = model.campaign.ID ? model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
		desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
		tracking += `/campaign/${id}`;
	}

	if (isBasketModel) {
		// basket model has RKI and skader on contact_information
		if (model.contact_information.rki?.toLowerCase() === 'ja') {
			desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
		}

		if (model.contact_information.skader !== '0') {
			desc += `<li><h2><i>Kunde har ${model.contact_information.skader} skader</i></h2></li>`;
		}
	} else {
		if (model.additionalInfo.rki?.toLowerCase() === 'ja') {
			desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
		}

		if (model.additionalInfo.skader !== '0') {
			desc += `<li><h2><i>Kunde har ${model.additionalInfo.skader} skader</i></h2></li>`;
		}
	}

	return { desc, tracking };
};

export const buildPersonInfo = (model): string => {
	let desc = '<h2>Person</h2>';
	const now: Date = new Date();
	desc += addProp(
		'Email sendt',
		`${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${
			now.getMonth() + 1
		}-${now.getFullYear()}`
	);
	desc += addProp('Navn', model.contact_information.name);
	if (model.personInfo.address) {
		desc += addProp('Adresse', `${model.personInfo.address}`);
	} else if (model.personInfo.zipCode) {
		desc += addProp('Postnr./by', `${model.personInfo.zipCode} ${model.personInfo.zipName}`);
	}
	desc += addProp('Alder', model.personInfo.customerAge);
	if (model.contact_information.customerNo) {
		desc += addProp('KundeNr', model.contact_information.customerNo);
	} else {
		desc += addProp('Cpr', model.contact_information.cpr);
	}

	desc += addProp('Antal skader', model.additionalInfo.skader);
	desc += addProp('Kunde har accepteret betingelser', model.contact_information.accept ? 'Ja' : 'Nej');
	desc += addProp('Email', model.contact_information.email);
	desc += addProp('Telefonnummer', model.contact_information.phone);
	return desc;
};

export const buildCurrentInsuranceInfo = (model): string => {
	let desc = '<h2>Forsikring</h2>';
	desc += addProp('Rki', model.additionalInfo.rki);
	desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

	if (model.additionalInfo.existInsurance === 'ja') {
		desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
	}

	desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
	if (model.additionalInfo.validNow === 'nej') {
		desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
	}
	return desc;
};

export const handleOrder = async (
	uiComp,
	calculationMsg: string,
	specificFields?: { desc: string; tracking: string }
) => {
	try {
		const res = buildHeaderHighlights(uiComp.model);
		if (specificFields) {
			res.desc += specificFields.desc;
			res.tracking += specificFields.tracking;
		}
		res.desc = '<ul>' + res.desc + '</ul>';
		await submitOrder(res.desc, res.tracking, uiComp);
	} catch (e) {
		store.state.showSpinner = false;
		ModalSubmitToggle(uiComp.cms, calculationMsg);
		sendErrorMailToTeam(e, uiComp.model);
	}
};

export const homeFamilyHouseSpecificFields = (uiComp): { desc: string; tracking: string } => {
	const model = uiComp.model;
	const specificFields = {
		desc: '',
		tracking: '',
	};
	if (model.calculation?.addressSupplement > 1) {
		specificFields.desc += '<li><h2><i>Adresse tillæg</i></h2></li>';
		specificFields.tracking += '/addressSupplement';
	}

	if (model.personInfo.thatchedRoof === 'ja') {
		specificFields.desc += `<li><h2><i>Stråtag</i></h2></li>`;
	}
	return specificFields;
};

export const dogFields = (model, pack): string => {
	let desc = addProp('Forsikringstype', pack.name);
	desc += addProp('Antal hunde', model.dogInfo.dogCount);

	desc += addProp('<br>Navn hund 1', model.dogInfo.dogName1);
	desc += addProp('Race', model.dogInfo.dogType1);
	desc += addProp('Race ID', model.dogInfo.dogTypeId1);
	desc += addProp('Køn', model.dogInfo.gender1 === 'T' ? 'Tæve' : 'Han');
	desc += addProp('Født', model.dogInfo.birthdate1);
	if (model.dogInfo.dogCount > 1) {
		desc += addProp('<br>Navn hund 2', model.dogInfo.dogName2);
		desc += addProp('Race', model.dogInfo.dogType2);
		desc += addProp('Race ID', model.dogInfo.dogTypeId2);
		desc += addProp('Køn', model.dogInfo.gender2 === 'T' ? 'Tæve' : 'Han');
		desc += addProp('Født', model.dogInfo.birthdate2);
	}
	if (model.dogInfo.healthy) {
		desc += addProp('<br>Hund(e) sund og rask', model.dogInfo.healthy);
		desc += addProp('Hund(e) tidligere været syg', model.dogInfo.beenUnhealthy);
		desc += addProp('Hund(e) Været ved dyrlæge p.g.a. sydgom/skade', model.dogInfo.vet);
		desc += addProp('Hund(e) vaccineret og over 8 uger', model.dogInfo.vaccine);
	}
	desc += '<br>';

	return desc;
};

export const childrenFields = (model, addHeader: boolean, addCurrentInsurance: boolean): string => {
	let desc = '';
	if (addHeader) {
		if (model.familyInfo.personCountId > 0) {
			desc += '<br><b>' + (model.familyInfo.personCountId + 1) + ' Børn</b><br>';
		} else {
			desc += '<br><b>' + (model.familyInfo.personCountId + 1) + ' Barn</b><br>';
		}
	}

	for (let i = 0; i <= model.familyInfo.personCountId; i++) {
		desc += '<br><b>Barn ' + (i + 1) + '</b><br>';
		desc += addProp('navn ', model.familyInfo['name' + i]);
		desc += addProp('alder ', model.familyInfo['age' + i] + ' år');
		desc += addProp('cpr ', model.familyInfo['cpr' + i]);
		if (model.familyInfo['workId' + i]) {
			desc += addProp('job ', model.familyInfo['work' + i]);
			desc += addProp('jobId ', model.familyInfo['workId' + i]);
		}

		if (addCurrentInsurance) {
			desc += addProp('Eksisterende forsikring', model.familyInfo['existInsurance' + i]);
			if (model.familyInfo['existInsurance' + i] === 'ja') {
				desc += addProp('Nuværende selskab', model.familyInfo['existingInsurance' + i]);
			}
		}
		if (i === model.familyInfo.personCountId) {
			desc += '<br>';
		}
	}
	return desc;
};
