import BuyTravel from './BuyTravelComponent';
import { defaultConfig } from '@/cms/api/ApiConfig';
import store from '@/store/store';
import AxiosService from '@/services/axiosService';
import {
	callAllCalculations,
	getRisksForPackage,
	mapCoveragesFromProductConfig,
	onePackageCalcEpi,
	prepareCalculations,
	setUpExcesses,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { Formatter } from '../services/ValidationService';
import { TravelSettings } from './TravelSettings';

export default class TravelCalculator {
	private model: any; // the data structure for state and properties
	private cms: TravelSettings; // settings from EPI
	private calcConfig: any;
	public successCalc = true;
	public axiosService: AxiosService;
	private calculatorUrl: string;
	private calculatorInfo: Array<any>;
	public stopSpinner = false;

	constructor(buyTravel: BuyTravel) {
		this.model = buyTravel.model;
		this.cms = buyTravel.cms;
		this.calculatorInfo = JSON.parse(buyTravel.calculatorInfoBlock.markup);

		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/travel/quotes';
		this.axiosService = new AxiosService(this.calculatorUrl);

		this.setUpConfig();
	}

	private mapCoveragesFromCalc() {
		mapCoveragesFromProductConfig(this.model, this.cms);
		// change sum on afbestilling if other sum present in package
		this.model.calculation.abCalc.packages.forEach((pack, packageInx) => {
			try {
				if (pack.sums) {
					const sums_ = JSON.parse(pack.sums);
					const [covId, sum] = Object.entries(sums_[0])[0];
					// override CMS settings
					this.cms.cancelSum = sum as number;
					this.cms.cancelSumId = covId;
				}
				const cancelSum = this.cms.cancelSum;
				const featureId = this.cms.cancelSumId;

				// only default excess
				const res = this.model.calculation.allCoverages.values().next().value.get(packageInx);
				res.forEach((cov) => {
					if (cov.featureId === featureId) {
						// '3' - afbestilling
						const sumFormatted = Formatter.format(cancelSum);
						cov.description = cov.description
							.replace('30.000', sumFormatted)
							.replace('60.000', sumFormatted)
							.replace('100.000', sumFormatted)
							.replace('150.000', sumFormatted);
					}
				});
			} catch (e) {
				console.error(e);
				// wrong config
			}
		});
	}

	public async setUpConfig() {
		setupCalculator(this, this.calculatorInfo);
		const calculatorInfo = this.calculatorInfo.find(
			(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
		);

		this.model.calculation.abCalc.segmentedProductProperties = ['Europa', 'Verden']; // destinations
		this.model.calculation.abCalc.familyMemberCountOptions = calculatorInfo.familyMemberCountOptions;

		setupPackages(this.model, this.cms, this.calcConfig);

		this.mapCoveragesFromCalc();
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model);
	}

	public async getCalculations(): Promise<boolean> {
		prepareCalculations(this.model, this.cms, true);
		const res = await callAllCalculations(
			this,
			this.model.calculation.abCalc.excessIdDefault,
			false,
			'Travel calculator'
		);
		this.model.choosePackage.ownRiskId = this.model.calculation.abCalc.excessIdDefault;
		return res;
	}
	public async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
		return await onePackageCalcEpi(this, monthly, excessId, packageId, true, 'Travel calculator');
	}

	public createParamsEpi(excessId: number, packageId: number, monthlyPrices: number) {
		//  https://preprod-api.almbrand.dk/insurance/travel/quotes/?
		// customer_age=34&
		// post_code=3480&
		// destination=europa&
		// cancel_sum=30000&
		// family_member_count=2&
		// plus_customer=PK0&
		// payments_per_year=1&
		// selected=1,2,3,5,8,9,10,11,12,13,14,15,16,17,18,19,30,31,32,33,34,35,36,37,38,42&
		// optional=41
		const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g, ''));
		let cancelSum = this.cms.cancelSum; // afbestillingssum - default from CMS

		// change sum on afbestilling if sum present in package
		this.model.calculation.abCalc.packages.forEach((pack) => {
			if (pack.id === packageId) {
				if (pack.sums) {
					try {
						const sums = JSON.parse(pack.sums);
						const [coverageId, sum] = Object.entries(sums[0])[0];
						cancelSum = sum as number;
					} catch (e) {
						console.error(e);
						// wrong config
					}
				}
			}
		});

		let groupAgreement = 0;
		if (this.model.campaign.valid) {
			groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
		}

		// create params
		const params = {
			plus_customer: 'PK0',
			customer_age: customerAge,
			family_member_count: this.model.personInfo.familyMemberCountId,
			destination: this.model.choosePackage.segmentedProductProperty,
			post_code: `${this.model.personInfo.zipCode}`,
			group_agreement: groupAgreement < 1 ? groupAgreement : undefined,
			cancel_sum: cancelSum,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: getRisksForPackage(packageId, this.model),
		};
		return params;
	}
}
