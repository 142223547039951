import { CalculatorComponent } from '../BuyInsuranceComponent';
import {
	addProp,
	buildExistingProductsDescription,
	buildCreditCardInfo,
	buildPersonInfo,
	buildPaymentInfo,
	handleOrder,
	homeFamilyHouseSpecificFields,
	childrenFields,
} from '../services/OrderService';
import MapOrderFlow from '../types/MapOrderFlow';

export default class MapOrderDescription extends MapOrderFlow {
	public constructor(uiComp: CalculatorComponent) {
		super(uiComp);
	}

	public async orderByEmail() {
		const specificFields = homeFamilyHouseSpecificFields(this.uiComp);
		await handleOrder(this.uiComp, 'Family submitOrder', specificFields);
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		desc += buildPersonInfo(this.model);

		desc += addProp('Antal personer i husstand', this.model.personInfo.familyMemberCount);
		desc += addProp('Børn', this.model.familyInfo.personCount);

		desc += buildExistingProductsDescription(this.model, this.cms);

		desc += '<h2>Forsikring</h2>';
		desc += addProp('Rki', this.model.additionalInfo.rki);
		desc += addProp('Gyldig hurtigst mulig', this.model.additionalInfo.validNow);
		if (this.model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', this.model.additionalInfo.validFromDate);
		}

		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', this.pack.name);

		desc += '<br><b>Indbo</b><br>';
		desc += addProp('Selvrisiko', (this.uiComp as any).getExcessAmount());
		desc += addProp('Forsikret indbo sum', this.model.choosePackage.insuredValue);
		desc += addProp('Eksisterende forsikring', this.model.additionalInfo.existInsurance);

		if (this.model.additionalInfo.existInsurance === 'ja') {
			desc += addProp('Nuværende selskab', this.model.additionalInfo.existingInsurance);
		}

		desc += '<br><b>Børneulykke</b>';

		desc += childrenFields(this.model, true, true);

		desc += buildPaymentInfo(this.model, this.pack, 'FP_FP beregning indbo, Tia beregning ulykke');

		desc += buildCreditCardInfo(this.model, this.cms);

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
