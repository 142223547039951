import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import FieldValidator from '@/services/fieldValidatorService';
import { CarSettings } from '../CarSettings';
import { PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { jaNejArray, Validator, validNowArray, ValuePair } from '../../services/ValidationService';
import { getNextCardLinkText } from '../../services/NavigationService';
import { handleAdditionalStepClick, isInsuranceInfoFilled } from '../../services/AdditionalStepService';

@Options({
	name: 'AdditionalInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
		showInsuranceChoices: Boolean, // wether to show current and existing insurance choice
		existingInsuranceLabel: String,
		showValidNowOption: Boolean,
	},

	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class AdditionalInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete
	showInsuranceChoices: boolean; // wether to show current and existing insurance choice
	existingInsuranceLabel: string;
	showValidNowOption: boolean;

	public model!: any; // datastructure for user input (store)
	public cms!: CarSettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;

	public showFormerInsurance = true;
	public jaNejArray: Array<ValuePair> = jaNejArray;
	public validNowArray: Array<ValuePair> = validNowArray;

	public skaderOptions = ['0', '1', '2', '3+'];
	public skaderLabels = this.skaderOptions;

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
		const basket = store.getters.getSelectableGroup('basket');
		const activeIndex = store.getters.getActiveCalculatorInx;
		basket.forEach((product) => {
			if (product.includes(this.model.productName) && product !== this.model.productName + activeIndex) {
				const calc = store.getters.getCalculator(product);
				const existFormerInsurance = calc?.model?.additionalInfo.existFormerInsurance;
				if (existFormerInsurance) {
					this.model.additionalInfo.existFormerInsurance = existFormerInsurance;
					this.model.additionalInfo.formerInsurance = calc.model.additionalInfo.formerInsurance;
					this.model.additionalInfo.formerInsuranceId = calc.model.additionalInfo.formerInsuranceId;
					this.showFormerInsurance = false;
				}
			}
		});
	}

	public beforeUnmount(): void {
		window.removeEventListener('keyup', this.moveOn);
	}
	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public selectExistingInsurance(evt) {
		this.model.additionalInfo.existingInsurance = evt.selectedValue;
		const inx = this.cms.insuranceCompanies.indexOf(evt.selectedValue);
		this.model.additionalInfo.existingInsuranceId = this.cms.insuranceCompaniesIds[inx];

		this.model.additionalInfo.formerInsurance = undefined;
		this.model.additionalInfo.formerInsuranceId = undefined;
		this.model.additionalInfo.existFormerInsurance = undefined;
	}

	public selectFormerInsurance(evt) {
		this.model.additionalInfo.formerInsurance = evt.selectedValue;
		const inx = this.cms.insuranceCompanies.indexOf(evt.selectedValue);
		this.model.additionalInfo.formerInsuranceId = this.cms.insuranceCompaniesIds[inx];
	}

	// public handleValidFrom(evt) {
	//     if (this.model.additionalInfo.validFromDate)
	//     // try {
	//     //     // try nicyfi
	//     //     const res = this.model.additionalInfo.validFromDate.trim().match(/[\d]+/g);
	//     //     if(res && res.length > 2) {
	//     //         this.model.additionalInfo.validFromDate = `${res[0]}-${res[1]}-${res[2]}`;
	//     //     }
	//     // } catch(e) {
	//     // }
	// }

	public get isInsuranceInfoFilled() {
		return isInsuranceInfoFilled(this);
	}

	public handleRadioClick(evt: any) {
		const name = evt.name ? evt.name : evt.id;
		handleAdditionalStepClick(evt.value, name, this.model, this.cms);

		if (name === 'existInsurance') {
			this.model.additionalInfo.existInsurance = evt.value;
		} else if (name === 'existFormerInsurance') {
			this.model.additionalInfo.existFormerInsurance = evt.value;
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public get skaderOk() {
		return this.model.additionalInfo.skader && this.cms.damagesOk.includes(this.model.additionalInfo.skader);
	}

	public getNextCardLinkText(): string {
		return getNextCardLinkText(this);
	}
}
