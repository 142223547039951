import { CalculatorComponent } from '../BuyInsuranceComponent';
import {
	addProp,
	buildExistingProductsDescription,
	buildCreditCardInfo,
	buildPersonInfo,
	buildPaymentInfo,
	handleOrder,
	childrenFields,
} from '../services/OrderService';
import MapOrderFlow from '../types/MapOrderFlow';

export default class MapOrderDescription extends MapOrderFlow {
	public constructor(uiComp: CalculatorComponent) {
		super(uiComp);
	}

	public async orderByEmail() {
		await handleOrder(this.uiComp, 'AccidentChild submitOrder');
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		desc += buildPersonInfo(this.model);

		desc += buildExistingProductsDescription(this.model, this.cms);
		desc += addProp('Rki', this.model.additionalInfo.rki);

		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', this.pack.name);

		desc += childrenFields(this.model, true, true);

		desc += buildPaymentInfo(this.model, this.pack, 'Tia beregning');

		desc += buildCreditCardInfo(this.model, this.cms);

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
