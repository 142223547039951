import { isApp } from '@/services/environmentService';

export default {
	SET_EPI_ROUTING(state: any, epiRouting: []) {
		state.epiRouting = epiRouting;
	},

	SET_USABILLA_COUNT(state: any) {
		state.usabilla.count = state.usabilla.count + 1;
	},

	SET_USABILLA_MOUNTED(state: any) {
		state.usabilla.mounted = state.usabilla.mounted + 1;
	},

	SET_APP_REPLACE_ROUTE(state: any, appReplaceRoute: boolean) {
		if (isApp()) {
			// only app relevant
			state.appReplaceRoute = appReplaceRoute;
		}
	},
	SET_CONSENT_SET_IN_APP(state: any, consent: boolean) {
		state.consentSetInApp = consent;
	},

	// toggleMenu(state: any, payload) {
	//     state.isMenuOpen = payload;
	// },
	setDeviceType(state: any, payload) {
		state.isTouchDevice = payload;
	},
	// setAllowTracking(state: any, payload) {
	//     state.allowTracking = payload;
	// },
	RECAPTCHA_SITE_KEY(state: any, recaptchaSiteKey: string) {
		state.recaptchaSiteKey = recaptchaSiteKey;
	},
};
