import { HtmlBlock } from '@/cms/definitions/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings';

export class CarSettings extends BuyInsuranceSettings {
	public selvrisikoPopover: string = undefined;
	public kmForbrug: Array<string> = [];
	public carOwnerYears: Array<string> = [];
	public specialCarTitle: string = undefined;
	public specialCarContent: string = undefined;
	public specialCarTimeout = 1000;

	public vehicleDiscountEnabled: boolean;
	public vehicleDiscountEnabledInCampaign: Array<string> = [];
	public vehicleDiscount = 0; //0.15
	public vehicleDiscountGroups: Array<string> = []; //6¤7¤8$
	public electricFuelTypes: Array<string> = []; // elektricitet¤benzin¤diesel¤b/el hybrid
	public electricFuelTypeDiscount = 0; // 0.25
	public electricFuelTypeVehicleGroups: Array<string> = []; //6¤7¤8¤9$
	public electricFuelTypeDiscountEnabledInCampaign: Array<string> = [];
	public carBrandBlackList: Array<string> = [];
	public maxVehicleGroup = 9;
	public excludedVehicleGroups: Array<string> = [];
	public useKidFirst = false;

	public popupDmrTrack: boolean;
	public popupDmrId: string;
	public popupDmrTrackToken: string;
	public popupDmrTitle: string;
	public popupDmrContent: string;

	public ignoreGPSRequired = false;
	public brandModelSearchDelay = 500;
	public licensePlateSearchDelay = 500;
	public licensePlateErrorTitle = '<br/><I>Problem med nummerpladesøgning</I>';
	public licensePlateErrorContent =
		'Der er desværre i øjeblikket problemer med nummerpladesøgning. Prøv igen om et øjeblik eller kontakt os.';

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadCarSettings();
	}
	/**
	 * load settings from CMS if present
	 */
	private loadCarSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('selvrisikopopover=') > -1) {
					this.selvrisikoPopover = this.getSettingsValue(setting_);
				} else if (setting.indexOf('kmforbrug=') > -1) {
					this.kmForbrug = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('carowneryears=') > -1) {
					this.carOwnerYears = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('specialcartitle=') > -1) {
					this.specialCarTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('specialcarcontent=') > -1) {
					this.specialCarContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('specialcartimeout=') > -1) {
					const timeout = parseInt(this.getSettingsValue(setting_));
					this.specialCarTimeout = timeout;
				} else if (setting.startsWith('vehiclediscountenabled=')) {
					this.vehicleDiscountEnabled = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('vehiclediscountenabledincampaign=') > -1) {
					this.vehicleDiscountEnabledInCampaign = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('vehiclediscount=') > -1) {
					this.vehicleDiscount = parseFloat(this.getSettingsValue(setting_));
				} else if (setting.indexOf('vehiclediscountgroups=') > -1) {
					this.vehicleDiscountGroups = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('electricfueltypes=') > -1) {
					this.electricFuelTypes = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('electricfueltypediscount=') > -1) {
					this.electricFuelTypeDiscount = parseFloat(this.getSettingsValue(setting_));
				} else if (setting.indexOf('electricfueltypevehiclegroups=') > -1) {
					this.electricFuelTypeVehicleGroups = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('electricfueltypediscountenabledincampaign=') > -1) {
					this.electricFuelTypeDiscountEnabledInCampaign = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('carbrandblacklist=') > -1) {
					this.carBrandBlackList = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('maxvehiclegroup=') > -1) {
					this.maxVehicleGroup = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('excludedvehiclegroups=') > -1) {
					this.excludedVehicleGroups = this.getSettingsValue(setting_).split('¤');
				} else if (setting.startsWith('usekidfirst=')) {
					this.useKidFirst = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('popupdmrtrack=')) {
					this.popupDmrTrack = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('popupdmrid=') > -1) {
					this.popupDmrId = this.getSettingsValue(setting_);
				} else if (setting.indexOf('popupdmrtracktoken=') > -1) {
					this.popupDmrTrackToken = this.getSettingsValue(setting_);
				} else if (setting.indexOf('popupdmrtitle=') > -1) {
					this.popupDmrTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('popupdmrcontent=') > -1) {
					this.popupDmrContent = this.getSettingsValue(setting_);
				} else if (setting.startsWith('ignoregpsrequired=')) {
					this.ignoreGPSRequired = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('brandmodelsearchdelay=') > -1) {
					this.brandModelSearchDelay = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('licenseplatesearchdelay=') > -1) {
					this.licensePlateSearchDelay = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('licenseplateerrortitle=') > -1) {
					this.licensePlateErrorTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('licenseplateerrorcontent=') > -1) {
					this.licensePlateErrorContent = this.getSettingsValue(setting_);
				}
			});
		} catch (e) {
			console.error(e);
		}
	}
	public OBSOLETE_productInfoTIAIntegration = {
		products: {
			product_id: 'SS1BVV9QQi00OQ==',
			code: 'I-AU_PB-49',
			title: 'Bilforsikring',
			tags: '#private #insurance #pb',
			optional_feature_ids: [
				'SS1BVV9QQi00OS00OQ==',
				'SS1BVV9QQi00OS02Mw==',
				'SS1BVV9QQi00OS02Mg==',
				'SS1BVV9QQi00OS01MQ==',
				'SS1BVV9QQi00OS0x',
				'SS1BVV9QQi00OS01MA==',
				'SS1BVV9QQi00OS02',
				'SS1BVV9QQi00OS01Mw==',
				'SS1BVV9QQi00OS00Nw==',
				'SS1BVV9QQi00OS00MQ==',
				'SS1BVV9QQi00OS0xNA==',
				'SS1BVV9QQi00OS01NA==',
				'SS1BVV9QQi00OS0xMA==',
				'SS1BVV9QQi00OS01Mg==',
			],
			features: [
				{
					feature_id: 'SS1BVV9QQi00OS01Mw==',
					code: 'I-AU_PB-49-53',
					tags: '#private #insurance',
					title: 'Parkeringsskade',
					description:
						'Dækker parkeringsskader på din bil, uden at du skal betale selvrisiko, når du ikke ved, hvem der har forårsaget skaden. ',
					sub_feature_ids: [],
					risk_sum: ['15000'],
					default_risk_sum: '15000',
				},
				{
					feature_id: 'SS1BVV9QQi00OS01Mg==',
					code: 'I-AU_PB-49-52',
					tags: '#private #insurance',
					title: 'Mekanisk skade',
					description:
						'Sikrer dig mod uventede værkstedsudgifter, og dækker pludselige mekaniske skader på bl.a. motor, gearkasse og kobling.',
					sub_feature_ids: [],
					risk_sum: ['25000'],
					default_risk_sum: '25000',
				},
				{
					feature_id: 'SS1BVV9QQi00OS01MA==',
					code: 'I-AU_PB-49-50',
					tags: '#private #insurance',
					title: 'Værdiforringelse',
					description:
						'Ved større skader kan værdien af din bil forringes. Hvis reparationsudgiften overstiger 10 % af bilens nyværdi, får du udbetalt 10 % af reparationsudgiften som kompensation for bilens værdiforringelse. Dækningen ophører ved første hovedforfald eller policeændring, efter bilen er blevet 3 år.',
					sub_feature_ids: [],
					risk_sum: ['29544'],
					default_risk_sum: '29544',
				},
				{
					feature_id: 'SS1BVV9QQi00OS00OQ==',
					code: 'I-AU_PB-49-49',
					tags: '#private #insurance',
					title: 'Eftermonteret tilbehør',
					description:
						'Dækker bilens eftermonterede ekstraudstyr eller tilbehør, f.eks. musik- eller multimedieanlæg, navigation og højtalere. Du har mulighed for at udvide dækningen med et højere beløb.',
					sub_feature_ids: [],
				},
				{
					feature_id: 'SS1BVV9QQi00OS0xNA==',
					code: 'I-AU_PB-49-14',
					tags: '#private #insurance',
					title: 'Ung fører',
					description:
						'Giver hjemmeboende børn mulighed for at optjene anciennitet, når de benytter deres forældres bil. Ancienniteten kan anvendes, når de selv skal oprette en Bilforsikring hos Alm. Brand.',
					sub_feature_ids: [],
				},
				{
					feature_id: 'SS1BVV9QQi00OS01NA==',
					code: 'I-AU_PB-49-54',
					tags: '#private #insurance',
					title: 'Delkasko',
					description: 'Dækker brand, tyveri og røveri af bilen. Delkasko dækker også følgende:',
					sub_feature_ids: ['SS1BVV9QQi00OS01Ng==', 'SS1BVV9QQi00OS01Nw=='],
				},
				{
					feature_id: 'SS1BVV9QQi00OS01MQ==',
					code: 'I-AU_PB-49-51',
					tags: '#private #insurance',
					title: 'Afleveringsforsikring - leasing',
					description:
						'Når du afleverer din leasingbil, skal du kun betale én selvrisiko, selvom bilen har flere mindre skader - f.eks. buler og ridser. Afleveringsforsikringen omfatter biler, der er op til 4 år gamle på afleveringstidspunktet. Afleveringsforsikringen kan købes, indtil 1 måned efter at aftalen med leasingselskabet er trådt i kraft.',
					sub_feature_ids: [],
					risk_sum: ['25000'],
					default_risk_sum: '25000',
				},
				{
					feature_id: 'SS1BVV9QQi00OS02Mw==',
					code: 'I-AU_PB-49-63',
					tags: '#private #insurance',
					title: 'Påkørsel af dyr',
					description: 'Dækker, så du ikke skal betale selvrisiko, hvis du påkører et dyr.',
					sub_feature_ids: [],
				},
				{
					feature_id: 'SS1BVV9QQi00OS0x',
					code: 'I-AU_PB-49-1',
					tags: '#private #insurance',
					title: 'Ansvar',
					description:
						'Dækker, hvis du gør skade på andre mennesker, deres ting eller køretøjer med din bil.',
					sub_feature_ids: [],
				},
				{
					feature_id: 'SS1BVV9QQi00OS00Nw==',
					code: 'I-AU_PB-49-47',
					tags: '#private #insurance',
					title: 'Udvidet glas',
					description:
						'Dækker reparation af bilens glas, uden at du skal betale selvrisiko. Hvis ruden ikke kan repareres og skal udskiftes, skal du dog betale selvrisiko. En Udvidet Glasforsikring omfatter bilens ruder, spejle, lygteglas m.m.',
					sub_feature_ids: [],
				},
				{
					feature_id: 'SS1BVV9QQi00OS02',
					code: 'I-AU_PB-49-6',
					tags: '#private #insurance',
					title: 'Førerulykke',
					description:
						'Dækker, hvis du får varigt mén efter en eneulykke - uanset om du kører i egen bil eller en bil, du har lånt. I tilfælde af dødsfald får dine efterladte udbetalt erstatning.',
					sub_feature_ids: [],
					risk_sum: ['656529'],
					default_risk_sum: '656529',
				},
				{
					feature_id: 'SS1BVV9QQi00OS0xMA==',
					code: 'I-AU_PB-49-10',
					tags: '#private #insurance',
					title: 'Alm. Brand Vejhjælp',
					description:
						'<p>Du kan kontakte Alm. Brand vejhjælp døgnet rundt på 38 48 88 22.</p><p><br></p><p>Dækningen tilbyder en række services: </p><p></p><p><br></p><p><span style="font-weight: bold;">Transport:</span> Mulighed for transport af din bil, fører og passagerer, hvis bilen ikke kan køre videre, eller føreren bliver syg.</p><p><br></p><p><span style="font-weight: bold;">Starthjælp:</span> Dækker hjælp til at starte din bil, hvis den ikke kan køre videre.</p><p><br></p><p><span style="font-weight: bold;">Nødreparation og hjulskift:</span> Dækker nødreparation og hjulskift.</p><p><br></p><span style="font-weight: bold;">Frit værkstedsvalg:</span> Dækker, så du frit kan vælge, hvilket værksted bilen skal transporteres til. <p></p><p><br></p><p><span style="font-weight: bold;">0 kr. i selvrisiko:</span> Dækker, så du ikke skal betale selvrisiko, når du bruger Alm. Brand Vejhjælp.</p><p><br></p><p><span style="font-weight: bold;">Vejhjælp i udlandet:</span> Dækker, så du kan få starthjælp og transport til nærmeste værksted i Europa.</p><p><br></p><p><span style="font-weight: bold;">Camping- og påhængsvogn:</span> Dækker transport af camping- og påhængsvogne.</p>',
					sub_feature_ids: [],
				},
				{
					feature_id: 'SS1BVV9QQi00OS00MQ==',
					code: 'I-AU_PB-49-41',
					tags: '#private #insurance',
					title: 'Kasko',
					description: 'Dækker færdselsuheld, brand og tyveri og røveri af bilen.',
					sub_feature_ids: ['SS1BVV9QQi00OS04', '==', 'SS1BVV9QQi00OS05'],
				},
				{
					feature_id: 'SS1BVV9QQi00OS02Mg==',
					code: 'I-AU_PB-49-62',
					tags: '#private #insurance',
					title: 'Udvidet førerulykke',
					description:
						'Dækker, hvis du får varigt mén efter en eneulykke - uanset om du kører i egen bil eller en bil, du har lånt. I tilfælde af dødsfald får dine efterladte udbetalt erstatning. Herudover dækker forsikringen erhvervsevnetab, tabt arbejdsfortjeneste, svie og smerte og udgifter til behandling.',
					sub_feature_ids: [],
				},
			],
			sub_features: [
				{
					sub_feature_id: 'SS1BVV9QQi00OS01Nw==',
					code: 'I-AU_PB-49-57',
					tags: '#private #insurance',
					title: 'Retshjælp',
					description: 'Dækker udgifter til retssager, hvor din bil er indblandet.',
				},
				{
					sub_feature_id: 'SS1BVV9QQi00OS04',
					code: 'I-AU_PB-49-8',
					tags: '#private #insurance',
					title: 'Redning i udlandet',
					description:
						'Dækker, hvis du har behov for redningshjælp, mens du kører bil i udlandet. Det er servicen SOS-International, der yder assistance via rødt kort-ordningen.',
				},
				{
					sub_feature_id: 'SS1BVV9QQi00OS05',
					code: 'I-AU_PB-49-9',
					tags: '#private #insurance',
					title: 'Retshjælp',
					description: 'Dækker udgifter til retssager, hvor din bil er indblandet.',
				},
				{
					sub_feature_id: 'SS1BVV9QQi00OS01NQ==',
					code: 'I-AU_PB-49-55',
					tags: '#private #insurance',
					title: 'Friskade',
					description:
						'Dækker, så du ikke skal betale selvrisiko, hvis din bil er udsat for brand, tyveri, seriehærværk eller nedstyrtende genstande.',
				},
				{
					sub_feature_id: 'SS1BVV9QQi00OS01Ng==',
					code: 'I-AU_PB-49-56',
					tags: '#private #insurance',
					title: 'Redning i udlandet',
					description:
						'Dækker, hvis du har behov for redningshjælp, mens du kører bil i udlandet. Det er servicen SOS-International, der yder assistance via rødt kort-ordningen.',
				},
			],
		},
	};
}
