import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { mapState } from 'vuex';
import { HtmlBlock, CardBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { DogSettings } from './DogSettings';
import { Model, STEPS } from './Model';
import DogCalculator from './DogCalculator';
import ChoosePackageStepComponent from '../commonSteps/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../commonSteps/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../commonSteps/OverviewStepComponent.vue';
import AdditionalInfoStepComponent from '../commonSteps/AdditionalInfoStepComponent.vue';
import AdditionalQuestionsInfoStepComponent from './steps/AdditionalQuestionsInfoStepComponent.vue';
import DogInfoStepComponent from './steps/DogInfoStepComponent.vue';
import PersonInfoStepComponent from './steps/PersonInfoStepComponent.vue';
import PaymentStepComponent from '../commonSteps/PaymentStepComponent.vue';
import ReceiptStepComponent from '../commonSteps/ReceiptStepComponent.vue';
import { RESET_CALCULATORS, CALCULATOR_LOADED, INIT_CALCULATOR } from '@/store/modules/calculatorContext';
import CampaignStepComponent from '../commonSteps/CampaignStepComponent.vue';
import { cloneDeep } from 'lodash';
import store from '@/store/store';
import { gotoAddInsurances, nextStep, setFirstCard } from '../services/NavigationService';
import { resetSelectedCalculation, subscribeBasketChange } from '../services/CalculationService';
import {
	getShowReceipt,
	isValidAdditionalInfo,
	isValidAlmbrandProducts,
	isValidContactInfo,
	isValidPackage,
	isValidPaymentInfo,
	showValidNowOption,
	Validator,
} from '../services/ValidationService';
import { getSubtitle, setDetailsYearlyPrice, setHighLights } from '../services/ProductService';
import { initComponent, STEP } from '../services/UiStepService';
import MapOrderDescription from './MapOrderDescription';
import { CalculatorComponent } from '../BuyInsuranceComponent';

@Options({
	name: 'BuyInsuranceDogComponent',
	components: {
		UsabillaInPageComponent,
		ChoosePackageStepComponent,
		OverviewStepComponent,
		AdditionalInfoStepComponent,
		AdditionalQuestionsInfoStepComponent,
		DogInfoStepComponent,
		PersonInfoStepComponent,
		ContactInformationStepComponent,
		PaymentStepComponent,
		ReceiptStepComponent,
		CampaignStepComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	page() {
		return this.$route.query.page || 1;
	},
	props: {
		calculatorInfoBlock: Object as PropType<Array<HtmlBlock>>,
		contentBlocks: Object as PropType<Array<CardBlock>>,
		cardReceipt: Object as PropType<CardBlock>,
		settingsBlock: Object as PropType<HtmlBlock>,
		usabillaBlock: Object as PropType<UsabillaInPageBlock>,
		contentUrl: String, // Used by servcie to handle browser history
		headerBadgeTxt: String,
		hasBadgeText: Boolean,
	},
})
export default class BuyDog extends Vue implements CalculatorComponent {
	contentBlocks: Array<CardBlock>;
	cardReceipt: CardBlock;
	settingsBlock: HtmlBlock;
	usabillaBlock: UsabillaInPageBlock;
	contentUrl: string; // Used by servcie to handle browser history
	headerBadgeTxt: string;
	hasBadgeText: boolean;
	calculatorInfoBlock: HtmlBlock;

	// list and order of steps
	public steps: Array<string> = STEPS;

	public model!: any; // model from store
	public cms!: DogSettings; // setting from store
	public calculator: DogCalculator = null;
	public componentInit = false;
	public checkout = false;
	public dogQuestionsValid = true;
	private mapOrderDescription: MapOrderDescription;
	private unsubScribeBasketChange: Function;

	public async created() {
		if (!store.getters.getActiveCalculator) {
			// this is first load - update store
			const cms = new DogSettings(this.settingsBlock);
			if (cms.mockData === true) {
				Model.mock();
			}
			await store.dispatch(INIT_CALCULATOR, {
				cms,
				model: cloneDeep(Model.model),
			});
		} else {
			await store.dispatch(CALCULATOR_LOADED);
		}

		if (!(await initComponent(this))) {
			return;
		}

		this.calculator = new DogCalculator(this);

		setFirstCard(this);
	}

	public mounted(): void {
		this.unsubScribeBasketChange = subscribeBasketChange(this);
	}

	beforeUnmount() {
		if (this.unsubScribeBasketChange) {
			this.unsubScribeBasketChange();
		}
	}

	// called by service
	public addResetSubscription() {
		const unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === RESET_CALCULATORS) {
				setTimeout(() => {
					this.checkout = true;
					store.dispatch(INIT_CALCULATOR, {
						cms: this.cms,
						model: cloneDeep(Model.model),
					});
					unsubscribe();
				}, 2000);
			}
		});
	}

	public get showValidNowOption() {
		return showValidNowOption(this.model);
	}

	public get showDogQuestions(): boolean {
		return this.model.choosePackage.selectedPackage?.id === this.cms.sickAccidentpackageId;
	}

	public get isDogQuestionsValid() {
		if (!this.showDogQuestions) {
			return true;
		}
		return (
			this.model.dogInfo.vaccine === 'ja' &&
			this.model.dogInfo.vet === 'nej' &&
			this.model.dogInfo.healthy === 'ja' &&
			this.model.dogInfo.beenUnhealthy === 'nej'
		);
	}

	public async gotoCard(cardName: string): Promise<boolean> {
		if (cardName === STEP.DOG_INFO || cardName === STEP.PERSON_INFO) {
			resetSelectedCalculation(this.model);
		}

		if (cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
			this.insureNiceDates('birthdate1');
			if (this.model.dogInfo.dogCount === 2) {
				this.insureNiceDates('birthdate2');
			}
		}

		if (cardName === STEP.PACKAGE) {
			this.model.choosePackage.ownRiskId = this.model.calculation.abCalc.excessIdDefault;
			this.calculator.setupExcessList();
		}

		if (cardName === STEP.PAYMENT) {
			// force user to choose yearly/monthly payment to ensure progressive steps
			this.model.choosePackage.monthYear = undefined;
			this.model.payment.userSelected = false;
		}

		this.model.currentCardName = cardName;

		if (cardName === STEP.ORDER) {
			store.state.showSpinner = true;
			if (!this.isValid(this.model.currentCardName)) {
				store.state.showSpinner = false;
				return false;
			}
			this.mapOrderDescription = new MapOrderDescription(this);
			await this.mapOrderDescription.orderByEmail();
			return false;
		}
		return true;
	}

	/**
	 * insure correct format day/month
	 * @param fieldName
	 * @param val
	 * @returns
	 */
	/* eslint-disable */
	private insureNiceDates(fieldName) {
		const val = this.model.dogInfo[fieldName];
		if (!Validator.isValidBeforeNow(val)) {
			return;
		}
		try {
			if (val.length === 10) {
				return;
			}
			const res = val.trim().match(/[\d]+/g);

			if (res?.length > 2) {
				for (let i = 0; i < 2; i++) {
					res[i] = res[i].length < 2 ? '0' + res[i] : res[i];
				}
				const niceRes = `${res[0]}-${res[1]}-${res[2]}`;
				if (this.model.dogInfo[fieldName] === val && this.model.dogInfo[fieldName] !== niceRes) {
					this.model.dogInfo[fieldName] = niceRes;
				}
			}
		} catch (e) {}
	}
	/* eslint-enable */

	/**
	 * when nextbtn is clicked - check for valid
	 * @param cardName
	 */
	public nextStep(cardName: string, addToHistory = true) {
		if (this.model.currentCardName === STEP.ADDITIONAL_INFO && this.showDogQuestions) {
			this.dogQuestionsValid = this.isDogQuestionsValid;
			if (!this.dogQuestionsValid) {
				return;
			}
		}
		nextStep(cardName, addToHistory, this);
	}

	public getSubtitle(cardName: string) {
		return getSubtitle(cardName, this.model);
	}

	public getCardTitle(card): string {
		if (card.name === STEP.PACKAGE) {
			return this.model.choosePackage.selectedPackage !== undefined ? this.cms.calculatedHeadline : card.title;
		}
		if (card.name === STEP.DOG_INFO) {
			return this.model.dogInfo.dogCount === 2 ? 'Dine Hunde' : card.title;
		}
		return card.title;
	}
	public isActiveCard(cardName: string): boolean {
		return cardName === this.model.currentCardName;
	}

	/* eslint-disable */
	public isValid(cardName): boolean {
		switch (cardName) {
			case STEP.DOG_INFO:
				let okDog = !!(this.model.dogInfo.dogCount && this.model.dogInfo.dogCount !== 3);
				let subtitle = '';
				if (okDog) {
					okDog = !!(
						this.model.dogInfo.dogName1 &&
						this.model.dogInfo.dogType1 &&
						this.model.dogInfo.gender1 &&
						Validator.isValidBeforeNow(this.model.dogInfo.birthdate1)
					);
					if (okDog) {
						subtitle = `${this.model.dogInfo.dogName1}`;
						if (this.model.dogInfo.dogCount === 2) {
							okDog = !!(
								this.model.dogInfo.dogName2 &&
								this.model.dogInfo.dogType2 &&
								this.model.dogInfo.gender2 &&
								Validator.isValidBeforeNow(this.model.dogInfo.birthdate2)
							);
							if (okDog) {
								subtitle += ` og ${this.model.dogInfo.dogName2}`;
							}
						}
					}
				}
				this.model.dogInfo.subtitle = subtitle;
				return okDog;
			case STEP.PERSON_INFO:
				return isValidAlmbrandProducts(this.model);
			case STEP.PACKAGE:
				return isValidPackage(this);
			case STEP.OVERVIEW:
				return isValidPackage(this);
			case STEP.CONTACT_INFORMATION:
				return isValidContactInfo(this.model, this.cms);
			case STEP.ADDITIONAL_INFO:
				return this.isDogQuestionsValid && isValidAdditionalInfo(false, this.model, this.cms);
			case STEP.PAYMENT:
				return isValidPaymentInfo(this.model, this.cms);
			default:
				return true;
		}
	}
	/* eslint-enable */

	// called by service
	public setOverviewData() {
		setHighLights(this.model);

		this.model.overview.details = [];

		this.model.overview.details.push('Antal hunde: ' + this.model.dogInfo.dogCount);
		this.model.overview.details.push('Navn på hund: ' + this.model.dogInfo.dogName1);
		this.model.overview.details.push('Race: ' + this.model.dogInfo.dogType1);

		if (this.model.dogInfo.dogCount === 2) {
			this.model.overview.details = this.model.overview.details.concat([
				'Navn på hund: ' + this.model.dogInfo.dogName2,
				'Race: ' + this.model.dogInfo.dogType2,
			]);
		}
		setDetailsYearlyPrice(this.model, this.cms);
	}

	/** called by service */
	public buildDescriptionForEmail(desc: string) {
		return this.mapOrderDescription.buildDescriptionForEmail(desc);
	}

	public gotoAddInsurances(step: string) {
		gotoAddInsurances(step, this);
	}

	public get showReceipt() {
		return getShowReceipt(this);
	}
}
