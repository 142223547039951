import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { HomeSettings } from './HomeSettings';
import { Model, STEPS } from './Model';
import HomeCalculator from './HomeCalculator';
import ChoosePackageStepComponent from '../commonSteps/ChoosePackageStepComponent.vue';
import OverviewStepComponent from '../commonSteps/OverviewStepComponent.vue';
import AdditionalInfoStepComponent from '../commonSteps/AdditionalInfoStepComponent.vue';
import PersonInfoStepComponent from './steps/PersonInfoStepComponent.vue';
import ContactInformationStepComponent from '../commonSteps/ContactInformationStepComponent.vue';
import PaymentStepComponent from '../commonSteps/PaymentStepComponent.vue';
import ReceiptStepComponent from '../commonSteps/ReceiptStepComponent.vue';
import CampaignStepComponent from '../commonSteps/CampaignStepComponent.vue';
import { cloneDeep } from 'lodash';
import { PropType } from 'vue';
import { CardBlock, HtmlBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { CALCULATOR_LOADED, INIT_CALCULATOR, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { gotoAddInsurances, nextStep, setFirstCard } from '../services/NavigationService';
import { resetSelectedCalculation, subscribeBasketChange } from '../services/CalculationService';
import {
	getShowReceipt,
	handleValidCustomerAge,
	isValidAdditionalInfo,
	isValidAlmbrandProducts,
	isValidContactInfo,
	isValidPackage,
	isValidPaymentInfo,
	showValidNowOption,
	Validator,
} from '../services/ValidationService';
import { getCardTitle, getSubtitle, setDetailsYearlyPrice, setHighLights } from '../services/ProductService';
import { initComponent, STEP } from '../services/UiStepService';
import MapOrderDescription from './MapOrderDescription';
import { CalculatorComponent } from '../BuyInsuranceComponent';

@Options({
	name: 'BuyInsuranceHomeComponent',
	props: {
		calculatorInfoBlock: Object as PropType<Array<HtmlBlock>>,
		contentBlocks: Object as PropType<Array<CardBlock>>,
		cardReceipt: Object as PropType<CardBlock>,
		settingsBlock: Object as PropType<HtmlBlock>,
		usabillaBlock: Object as PropType<UsabillaInPageBlock>,
		contentUrl: String, // Used by servcie to handle browser history
		headerBadgeTxt: String,
		hasBadgeText: Boolean,
	},
	components: {
		UsabillaInPageComponent,
		PersonInfoStepComponent,
		ChoosePackageStepComponent,
		OverviewStepComponent,
		AdditionalInfoStepComponent,
		PaymentStepComponent,
		ReceiptStepComponent,
		CampaignStepComponent,
		ContactInformationStepComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class BuyHome extends Vue implements CalculatorComponent {
	contentBlocks: Array<CardBlock>;
	cardReceipt: CardBlock;
	settingsBlock: HtmlBlock;
	usabillaBlock: UsabillaInPageBlock;
	contentUrl: string; // Used by servcie to handle browser history
	headerBadgeTxt: string;
	hasBadgeText: boolean;
	calculatorInfoBlock: HtmlBlock;

	// list and order of steps
	public steps: Array<string> = STEPS;

	public model!: any; // model from store
	public cms!: HomeSettings; // setting from store
	public calculator: HomeCalculator = null;
	public componentInit = false;
	public checkout = false;
	private mapOrderDescription: MapOrderDescription;
	private unsubScribeBasketChange: Function;

	public async created() {
		if (!store.getters.getActiveCalculator) {
			// this is first load - update store
			const cms = new HomeSettings(this.settingsBlock);
			if (cms.mockData === true) {
				Model.mock();
			}
			await store.dispatch(INIT_CALCULATOR, {
				cms,
				model: cloneDeep(Model.model),
			});
		} else {
			await store.dispatch(CALCULATOR_LOADED);
		}

		if (!(await initComponent(this))) {
			return;
		}

		this.calculator = new HomeCalculator(this);

		setFirstCard(this);
	}

	public mounted(): void {
		this.unsubScribeBasketChange = subscribeBasketChange(this);
	}

	beforeUnmount() {
		if (this.unsubScribeBasketChange) {
			this.unsubScribeBasketChange();
		}
	}

	// called by service
	public addResetSubscription() {
		const unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === RESET_CALCULATORS) {
				setTimeout(() => {
					this.checkout = true;
					store.dispatch(INIT_CALCULATOR, {
						cms: this.cms,
						model: cloneDeep(Model.model),
					});
					unsubscribe();
				}, 2000);
			}
		});
	}

	public get showValidNowOption() {
		return showValidNowOption(this.model);
	}

	public async gotoCard(cardName: string): Promise<boolean> {
		if (cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
			resetSelectedCalculation(this.model);
		}

		if (cardName === STEP.PACKAGE) {
			// check address
			store.state.showSpinner = true;
			const ok = await this.calculator.checkForValidAddress();
			store.state.showSpinner = false;
			if (!ok) {
				this.model.currentCardName = cardName;
				this.gotoCard(STEP.PERSON_INFO);
				return false;
			}
			this.calculator.setupExcessList();

			// check Age:
			if (!handleValidCustomerAge(this.model, this.cms)) {
				this.gotoCard(STEP.PERSON_INFO);
				return false;
			}
		}

		if (cardName === STEP.PAYMENT) {
			// force user to choose yearly/monthly payment to ensure progressive steps
			this.model.choosePackage.monthYear = undefined;
			this.model.payment.userSelected = false;
		}

		this.model.currentCardName = cardName;

		if (cardName === STEP.ORDER) {
			store.state.showSpinner = true;
			if (!this.isValid(this.model.currentCardName)) {
				store.state.showSpinner = false;
				return false;
			}
			this.mapOrderDescription = new MapOrderDescription(this);
			await this.mapOrderDescription.orderByEmail();
			return false;
		}
		return true;
	}

	/**
	 * when nextbtn is clicked - check for valid
	 * @param cardName
	 */
	public nextStep(cardName: string, addToHistory = true) {
		nextStep(cardName, addToHistory, this);
	}

	public getSubtitle(cardName: string) {
		return getSubtitle(cardName, this.model);
	}

	public isActiveCard(cardName: string): boolean {
		return cardName === this.model.currentCardName;
	}

	/* eslint-disable */
	public isValid(cardName): boolean {
		switch (cardName) {
			case STEP.PERSON_INFO:
				const okPerson =
					this.model.personInfo.address &&
					this.model.personInfo.familyMemberCount &&
					Validator.isValidAge(this.model.personInfo.customerAge) &&
					this.model.personInfo.thatchedRoof &&
					isValidAlmbrandProducts(this.model);
				if (okPerson) {
					this.model.personInfo.customerAge = this.model.personInfo.customerAge.trim().replace(/\D/g, '');
					// const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
					this.model.personInfo.subtitle = `${this.model.personInfo.customerAge} år, ${this.model.personInfo.address}`;
				}
				return okPerson;
			case STEP.PACKAGE:
				return (
					isValidPackage(this) &&
					this.model.choosePackage.insuredValueAmount !== undefined &&
					this.model.choosePackage.insuredValueAmount > 0
				);
			case STEP.OVERVIEW:
				return isValidPackage(this);
			case STEP.CONTACT_INFORMATION:
				return isValidContactInfo(this.model, this.cms);
			case STEP.ADDITIONAL_INFO:
				return isValidAdditionalInfo(false, this.model, this.cms);
			case STEP.PAYMENT:
				return isValidPaymentInfo(this.model, this.cms);
			default:
				return true;
		}
	}
	/* eslint-enable */

	// called by service
	public setOverviewData() {
		setHighLights(this.model);

		this.model.overview.details = [
			this.model.personInfo.customerAge + ' år',
			this.model.personInfo.address,
			'Stråtag: ' + this.model.personInfo.thatchedRoof,
			'Husstand: ' + this.model.personInfo.familyMemberCount,
			'Selvrisiko: ' + this.getExcessAmount(),
			'Indbo sum: ' + this.model.choosePackage.insuredValue,
		];

		setDetailsYearlyPrice(this.model, this.cms);
	}

	/** called by service */
	public buildDescriptionForEmail(desc: string) {
		return this.mapOrderDescription.buildDescriptionForEmail(desc);
	}

	public getExcessAmount(): string {
		const inx = this.model.ownRiskOptions.indexOf(this.model.choosePackage.ownRiskId);
		return this.model.ownRiskLabels[inx];
	}

	public gotoAddInsurances(step: string) {
		gotoAddInsurances(step, this);
	}

	public get showReceipt() {
		return getShowReceipt(this);
	}

	public getCardTitle(card): string {
		return getCardTitle(card, this.model, this.cms);
	}
}
