import BuyAccident from './BuyAccidentComponent';
import { AccidentSettings, productId } from './AccidentSettings';
import AxiosService from '@/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	finishCalculation,
	getDiscount,
	mapCoveragesFromProductConfig,
	prepareCalculations,
	setUpExcesses,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { Formatter } from '../services/ValidationService';
import { Modal, modalWithExit, togglePopup } from '../services/AlertService';
import { isDevelop } from '@/services/environmentService';
import { cloneDeep } from 'lodash';

export default class AccidentCalculator {
	private axiosService: AxiosService;
	private model: any; // the data structure for state and properties
	private cms: AccidentSettings; // settings from EPI
	private calcConfig: any;
	private customerAge: string = undefined;
	private calculatorInfo: Array<any>;

	constructor(buyAccident: BuyAccident) {
		this.model = buyAccident.model;
		this.cms = buyAccident.cms;
		this.calculatorInfo = JSON.parse(buyAccident.calculatorInfoBlock.markup);

		const accidentCalculatorUrl =
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/accident/offers';
		this.axiosService = new AxiosService(accidentCalculatorUrl);
		this.setUpConfig();
	}

	private get errorPopup(): Modal {
		const modal = modalWithExit(this.cms);
		modal.id = 'calcError';
		modal.title = this.cms.defaultCalcErrorTitle;
		modal.content = this.cms.defaultCalcErrorContent;
		modal.track = true;
		modal.trackToken = 'Accident calculation';

		if (this.cms.useBtnInlineFail) {
			modal.btnInlineLabel = this.cms.btnInlineFailLabel;
			modal.btnInlineStyle = this.cms.btnInlineFailStyle;
			modal.btnInlineIcon = this.cms.btnInlineIcon;
			modal.btnInlineAction = () => {
				this.getCalculations();
			};
		}
		return modal;
	}

	public async setUpConfig() {
		this.customerAge = this.model.personInfo.otherCustomerAge || this.model.personInfo.customerAge;
		setupCalculator(this, this.calculatorInfo);

		this.filterPackagesByAge();

		setupPackages(this.model, this.cms, this.calcConfig);

		mapCoveragesFromProductConfig(this.model, this.cms);
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model);
	}

	private filterPackagesByAge() {
		let customerAge = 20; // default
		if (this.customerAge) {
			customerAge = parseInt(this.customerAge.trim().replace(/\D/g, ''));
		}
		const packageIds = [];
		const packs = [];
		this.model.calculation.abCalc.packages.forEach((pack) => {
			if (customerAge >= pack.minAge && customerAge <= pack.maxAge) {
				packageIds.push(pack.id);
				packs.push(pack);
			}
		});
		this.model.calculation.abCalc.packageIds = packageIds;
		this.model.calculation.abCalc.packages = packs;
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		prepareCalculations(this.model, this.cms, false);
		// reset calculation and setup coverages by age
		await this.setUpConfig();
		this.model.calculation.abCalc.calculated = true;

		try {
			const rebate = getDiscount(this.cms);
			this.model.calculation.discount = rebate.discount;
			this.model.calculation.discountDisplay = rebate.discountDisplay;

			const promises = [];

			this.model.calculation.abCalc.packages.forEach((pack) => {
				const features = this.getFeatures(pack);
				promises.push(this.calculateQuickQuote(pack.name, features));
			});
			await Promise.all(promises);

			finishCalculation(this.model, this.cms);
			// togglePopup(this.cms, this.errorPopup);
			return Promise.resolve(true);
		} catch (err) {
			console.error('Accident offer', err);

			togglePopup(this.cms, this.errorPopup);
			store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;
			this.model.stopSpinner = false;
			return Promise.resolve(false);
		}
	}

	private getFeatures(pack) {
		const features = [];
		pack.includedCoveragesExpandedIds.forEach((riskId) => {
			const cov = cloneDeep(
				this.model.calculation.abCalc.coveragesDetails.filter((coverage) => riskId === coverage.riskId)[0]
			);
			cov.name = cov.riskId;
			features.push(cov);
		});
		return features;
	}
	private async calculateQuickQuote(productType: string, features: Array<any>): Promise<any> {
		// HACK - Around june every year, the calculator fails on 18 years and 66 years, because the API interprets the age for certain coverages faulty
		// increase age by one
		const customerAge = parseInt(this.customerAge.trim().replace(/\D/g, '')) + this.cms.hackAgeForApi;
		if (!features) {
			return Promise.reject();
		}
		const createOffer: any = {
			product: {
				product_name: productId,
				features,
				parameters: {
					age: customerAge,
					postalCode: this.model.personInfo.zipCode,
					employment: this.model.personInfo.workId,
				},
			},
		};

		try {
			const res = await this.axiosService.postRetry(undefined, createOffer);
			const offer = res.data;
			if (!offer || !offer.price || !offer.price.annually) {
				throw new Error();
			}
			this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
				if (calc.name === productType) {
					let totalPrice = offer.price.monthly;
					calc.basePrice = totalPrice;
					calc.statutoryFee = 0; // not returned by api

					// pluscustomer discount
					let discount = this.model.calculation.discount;
					if (this.model.campaign.valid) {
						discount -= this.model.campaign.discount;
					}

					totalPrice *= discount;

					// // campaign discount
					// if (this.model.campaign.valid)  {
					//     totalPrice -= (totalPrice * this.model.campaign.discount);
					// }

					calc.totalPrice = Math.round(totalPrice);
					calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

					totalPrice = offer.price.annually;
					calc.yearlyBasePrice = totalPrice;
					calc.yearlyStatutoryFee = 0; // not returned by api

					// pluscustomer discount
					// totalPrice *= this.model.calculation.discount;

					totalPrice *= discount;

					// // campaign discount
					// if (this.model.campaign.valid)  {
					//     totalPrice -= (totalPrice * this.model.campaign.discount);
					// }
					calc.yearlyPriceTotal = Math.round(totalPrice);
					calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
					updateCalcKeys(this.model);
				}
			});

			return Promise.resolve();
		} catch (err) {
			console.error(err);
			togglePopup(this.cms, this.errorPopup);
			if (isDevelop && this.cms.mockData) {
				// MOCK
				console.warn('USING MOCK DATA!');
				let add = 2000;
				this.model.calculation.abCalc.packs
					.get(this.model.calculation.abCalc.excessIdDefault)
					.forEach((calc) => {
						add += 100;
						if (calc.name === productType) {
							let totalPrice = add;
							calc.basePrice = totalPrice;
							calc.statutoryFee = 0; // not returned by api
							if (this.model.campaign.valid) {
								totalPrice -= totalPrice * this.model.campaign.discount;
							}

							calc.totalPrice = Math.round(totalPrice * this.model.calculation.discount);
							calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

							totalPrice = 12 * add * 0.97;
							calc.yearlyBasePrice = totalPrice;
							calc.yearlyStatutoryFee = 0; // not returned by api

							if (this.model.campaign.valid) {
								totalPrice -= totalPrice * this.model.campaign.discount;
							}
							calc.yearlyPriceTotal = Math.round(totalPrice * this.model.calculation.discount);
							calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
							updateCalcKeys(this.model);
						}
					});
			} else {
				return Promise.reject(err);
			}
		}
	}
}
