import BuyHome from './BuyHomeComponent';
import AxiosService from '@/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	callAllCalculations,
	getRisksForPackage,
	mapCoveragesFromProductConfig,
	onePackageCalcEpi,
	prepareCalculations,
	setUpExcesses,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { HomeSettings } from './HomeSettings';

export default class HomeCalculator {
	private model: any; // the data structure for state and properties
	private cms: HomeSettings; // settings from EPI
	private calcConfig: any;
	public successCalc = true;
	public axiosService: AxiosService;
	private calculatorUrl: string;
	private calculatorInfo: Array<any>;

	constructor(buyHome: BuyHome) {
		this.model = buyHome.model;
		this.cms = buyHome.cms;
		this.calculatorInfo = JSON.parse(buyHome.calculatorInfoBlock.markup);
		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/home/quotes';
		this.axiosService = new AxiosService(this.calculatorUrl);
		this.setUpConfig();
	}

	public async setUpConfig() {
		setupCalculator(this, this.calculatorInfo);

		const calculatorInfo = this.calculatorInfo.find(
			(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
		);

		this.model.calculation.abCalc.insuredValueOptions = calculatorInfo.insuredValueOptions;
		this.model.calculation.abCalc.familyMemberCountOptions = calculatorInfo.familyMemberCountOptions;

		setupPackages(this.model, this.cms, this.calcConfig);

		mapCoveragesFromProductConfig(this.model, this.cms);
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model, this.calcConfig.excessList);
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		const selectedExcessId =
			excessId || this.model.choosePackage.ownRiskId || this.model.calculation.abCalc.excessIdDefault;
		prepareCalculations(this.model, this.cms, true, selectedExcessId);
		return callAllCalculations(this, excessId, true, 'Home calculator');
	}

	public async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
		return await onePackageCalcEpi(this, monthly, excessId, packageId, true, 'Home calculator');
	}

	public createParamsEpi(excessId: number, packageId: number, monthlyPrices: number) {
		const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g, ''));
		const streetBuildingLetter = this.model.personInfo.houseNr.trim().replace(/[0-9]/g, ''); // remove numbers
		const streetNumber = this.model.personInfo.houseNr.trim().replace(/\D/g, ''); // remove characters

		let suite: number;
		let floorSideCode: string;
		if (this.model.personInfo.doorNr) {
			suite =
				this.model.personInfo.doorNr.trim().replace(/[0-9]/g, '') === ''
					? parseInt(this.model.personInfo.doorNr.trim())
					: undefined;
			floorSideCode =
				this.model.personInfo.doorNr.trim().replace(/\D/g, '', '') === ''
					? this.model.personInfo.doorNr.trim()
					: undefined;
		}

		let insuredValue = this.model.choosePackage.insuredValueAmount;
		if (!insuredValue) {
			insuredValue = this.model.calculation.abCalc.insuredValueOptions[0].value;
		}
		// district_name=xxx&
		// customer_age=45
		// insured_value=347000&
		// street_name=Adolphsvej&
		// street_building=19
		// &post_code=2820&
		// has_thatched_roof=n&
		// has_burglar_alarm=n&
		// family_member_count=2&
		// plus_customer=PK0&
		// excess_id=42&
		// payments_per_year=12&
		// selected=1,2,3,4,5,6,7,47&
		// optional=8,9,46

		//bournionv 1a
		// https://api.almbrand.dk/insurance/home/quotes?district_name=xxx&customer_age=45&insured_value=347000&street_name=Bournonvillesvej&street_building=1&street_building_letter=A&post_code=3480&has_thatched_roof=n&has_burglar_alarm=n&family_member_count=2&plus_customer=PK0,PK3&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47&optional=8,9,46

		// gasværks
		// https://api.almbrand.dk/insurance/home/quotes?district_name=xxx&customer_age=45&insured_value=347000&street_name=Gasværksvej&street_building=29&floor=2&floor_side_code=th&post_code=1656&has_thatched_roof=n&has_burglar_alarm=n&family_member_count=2&plus_customer=PK0,PK3&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47&optional=8,9,46

		//https://?district_name=xxx&customer_age=45&insured_value=347000&street_name=Bournonvillesvej&street_building=1&street_building_letter=A&post_code=3480&has_thatched_roof=y&has_burglar_alarm=n&family_member_count=2&plus_customer=PK0,PK3&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47&optional=8,9,46
		//https://?customer_age=45&district_name=xxx&insured_value=347000&street_name=Bournonvillesvej&street_building=1&street_building_letter=A&post_code=3480&hasThatchedRoof=y&has_burglar_alarm=n&family_member_count=1&plus_customer=PK0&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47
		// create params
		const params = {
			district_name: 'xxx', // ?
			customer_age: customerAge,
			insured_value: insuredValue,
			street_name: this.model.personInfo.streetName,
			street_building: streetNumber,
			street_building_letter: streetBuildingLetter !== '' ? streetBuildingLetter : undefined,
			floor: this.model.personInfo.floorNr,

			suite, // hvis tal
			floor_side_code: floorSideCode, // hvis ikke tal

			post_code: `${this.model.personInfo.zipCode}`,
			has_thatched_roof: this.model.personInfo.thatchedRoof === 'ja' ? 'y' : 'n',
			has_burglar_alarm: 'n',
			family_member_count: this.model.personInfo.familyMemberCountId,
			plus_customer: 'PK0',
			excess_id: `${excessId}`,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: getRisksForPackage(packageId, this.model),
			// kvhxValue: this.model.personInfo.kvhxValue, // ?
			// optional

			// selectedOptionalProducts: '46,9,8',
			// zipName: `${this.model.personInfo.zipName}`,
			// extendedBikeSum: this.cms.extendedBikeSum // is present in familyinsurance, currently not in homeInsurance
			// extendedPackage: `${packageId}`,
		};
		return params;
	}

	public async checkForValidAddress(): Promise<boolean> {
		const packageId = this.model.calculation.abCalc.packageIds[0];
		const excessId = this.model.calculation.abCalc.excessIdDefault;
		const ok = await this.onePackageCalcEpi(true, excessId, packageId);
		return Promise.resolve(ok);
	}
}
