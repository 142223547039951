import store from '@/store/store';
import { nextTick } from 'vue';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'ModalComponent',
	props: {
		id: { type: String, default: '123' },
		type: { type: String, default: 'default' },
		dismissible: { type: Boolean, default: false },
		trackingEnabled: { type: Boolean, default: true },
		trackingLabel: { type: String, default: undefined },
		bgColor: { type: String, default: '#fff' },
		padding: { type: String, default: undefined },
		top: { type: String, default: '0' },
		useModalTrigger: { type: Boolean, default: false },
	},
})
export default class ModalComponent extends Vue {
	id?: string; // use to trigger modal with event, ex. on element
	type!: string; // 'compact' | 'default'
	dismissible?: boolean; // compact modal is by default not dismissible, can be overridden if necessary
	trackingEnabled!: boolean; // used for tracking to distinguish different modals
	trackingLabel!: string; // used for tracking to distinguish different modals, NB! required if tracking is enabled
	bgColor?: string;
	padding?: string; // override padding, ie. '10px', '24px 32px', '0' etc.
	top?: string; // override top, ie. '10px', '50%' etc.
	useModalTrigger: boolean;
	isActive = false;
	scrollbarWidth: number;

	// LIFECYCLE
	created() {
		store.subscribeAction((action, state) => {
			if (action.type === 'toggleModal') {
				if (this.id === action.payload.id) {
					this.toggleModal(action.payload.isActive);
				}
			}
		});
	}

	public mounted(): void {
		this.scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
		document.addEventListener('keyup', this.handleKeyup);
		window.addEventListener('resize', this.handleResize);
	}

	beforeUnmount() {
		document.removeEventListener('keyup', this.handleKeyup);
		window.removeEventListener('resize', this.handleResize);
	}

	// METHODS
	public toggle(val?: boolean) {
		store.dispatch('toggleModal', { id: this.id, show: val });
	}

	private toggleModal(val?: boolean) {
		val === undefined ? (this.isActive = !this.isActive) : (this.isActive = val);

		if (this.isActive) {
			nextTick(() => {
				this.handleResize(); // position modal
				this.setBodyWidthWithoutScrollbar();
			});
		} else {
			this.setBodyWidthWithoutScrollbar();
		}
	}

	private handleKeyup(e: any) {
		if (e.keyCode === 27 && this.isActive && this.isDismissible) {
			// escape
			store.dispatch('toggleModal', { id: this.id, show: false });
		}
	}

	private handleResize() {
		const modalInner = (this as any).$refs.modalContent as HTMLElement;
		if (modalInner) {
			const modalHeight = modalInner.getBoundingClientRect().height;
			const modalMargins =
				parseInt(window.getComputedStyle(modalInner).getPropertyValue('margin-top'), 0) +
				parseInt(window.getComputedStyle(modalInner).getPropertyValue('margin-bottom'), 0);

			if (modalHeight + modalMargins > window.innerHeight || this.top !== '0') {
				// modal placement in top of viewport
				modalInner.style.cssText = `position:absolute; top: ${this.top}; background-color: ${this.bgColor}; padding: ${this.padding};`;
			} else {
				// set absolute position to prevent content jumping if height is changed
				const offsetTop = (window.innerHeight - modalHeight - modalMargins) / 2;
				modalInner.style.cssText = `position:absolute; top: ${offsetTop}px; background-color: ${this.bgColor}; padding: ${this.padding};`;
			}
		}
	}

	private setBodyWidthWithoutScrollbar() {
		const overflowStyle = this.isActive ? 'hidden' : 'initial';
		document.body.style.overflow = overflowStyle;
		if (this.isActive) {
			document.body.style.marginRight = this.scrollbarWidth + 'px';	
		} else {
			document.body.style.marginRight = '0px';
		}
		
	}

	// COMPUTED
	get isDismissible() {
		return (this.type === 'compact' && this.dismissible) || this.type === 'default';
	}

	get modalTypeClass() {
		return this.type === 'compact' ? 'modal__content--compact' : null;
	}

	get hasFooterSlot() {
		return !!(this as any).$slots.footer;
	}

	get trackingAttributesTrigger() {
		return this.trackingEnabled
			? {
					'data-gtm-action': 'modal',
					'data-gtm-label': `${this.trackingLabel}`,
			  }
			: null;
	}
}
