import BuyDog from './BuyDogComponent';
import AxiosService from '@/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	callAllCalculations,
	getDiscount,
	getRisksForPackage,
	mapCoveragesFromProductConfig,
	mapPriceResult,
	prepareCalculations,
	setPrices,
	setUpExcesses,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { modalHttp250Toggle } from '../services/AlertService';
import { DogSettings } from './DogSettings';

export default class DogCalculator {
	private model: any; // the data structure for state and properties
	private cms: DogSettings; // settings from EPI
	private calcConfig: any;
	// used for first three calculations, to see if there's known error codes
	public successCalc = true;
	private axiosService: AxiosService;
	private calculatorUrl: string;
	private calculatorInfo: Array<any>;

	constructor(buyDog: BuyDog) {
		this.model = buyDog.model;
		this.cms = buyDog.cms;
		this.calculatorInfo = JSON.parse(buyDog.calculatorInfoBlock.markup);

		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/dog/quotes';
		this.axiosService = new AxiosService(this.calculatorUrl);
		this.setUpConfig();
	}

	public async setUpConfig() {
		setupCalculator(this, this.calculatorInfo);
		// TODO KLK from config
		this.model.calculation.abCalc.dogCountOptions = ['1', '2', '3']; // dogcount
		this.model.calculation.abCalc.dogCountLabels = ['1', '2', 'Mere end 2'];

		if (!this.cms.alwaysUseSickAccident && getDiscount(this.cms).discount === 1) {
			// ikke pluskunde, fjern Stor pakke
			this.model.calculation.abCalc.packages = this.model.calculation.abCalc.packages.filter(
				(pack) => pack.id !== this.cms.sickAccidentpackageId
			);
			this.model.calculation.abCalc.packageIds = this.model.calculation.abCalc.packageIds.filter(
				(id) => id !== this.cms.sickAccidentpackageId
			);
		}

		setupPackages(this.model, this.cms, this.calcConfig);

		mapCoveragesFromProductConfig(this.model, this.cms);
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model);
	}

	public async getCalculations(): Promise<boolean> {
		prepareCalculations(this.model, this.cms, true);
		this.ensureCorrectDates();
		return callAllCalculations(this, this.model.calculation.abCalc.excessIdDefault, false, 'Home calculator');
	}

	/**
	 * make sure dates are in format dd-MM-yyyy for api calculation
	 */
	private ensureCorrectDates() {
		try {
			for (let i = 1; i < 3; i++) {
				// dog one and two
				if (this.model.dogInfo['birthdate' + i]) {
					this.model.dogInfo['birthdate' + i] = this.model.dogInfo['birthdate' + i]
						.trim()
						.replace(/\D/g, '-');
				}
			}
		} catch (e) {
			// move on - will fail in calculation
		}
	}

	public async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
		let dogCount = this.model.dogInfo.dogCount;

		let responseSplit = undefined;
		if (dogCount > 1 && packageId === this.cms.sickAccidentpackageId) {
			dogCount = 1;
			// lav 2 requests og læg tal sammen på response
			// kan kun tilbyde syge- ulykkedækning til pluskunder
			responseSplit = await this.axiosService.getRetryDefaultUrl(
				this.createParamsEpiNew(
					excessId,
					packageId,
					monthly ? 1 : 0,
					this.model.dogInfo.gender2,
					dogCount,
					this.model.dogInfo.dogTypeId2,
					this.model.dogInfo.birthdate2
				)
			);
		}

		let response = undefined;
		if (responseSplit === undefined || responseSplit.status === 200) {
			response = await this.axiosService.getRetryDefaultUrl(
				this.createParamsEpiNew(
					excessId,
					packageId,
					monthly ? 1 : 0,
					this.model.dogInfo.gender1,
					dogCount,
					this.model.dogInfo.dogTypeId1,
					this.model.dogInfo.birthdate1
				)
			);
		}

		// response = JSON.parse(JSON.stringify(DogTestCalculation));

		const responseAny = response || responseSplit;

		if (responseAny?.status !== 200) {
			this.successCalc = false;
			modalHttp250Toggle(this.cms, responseAny?.status, 'Dog calculator', this);
			return Promise.resolve(false);
		}
		mapPriceResult(response);
		// Hack calculator is calculating wrong!!!!
		this.handleSpecialFeeSickDisease(packageId, response);
		// put results from two calculations together, if syge- ulykkedækning and 2 dogs in calculation
		if (responseSplit) {
			mapPriceResult(responseSplit);
			this.handleSpecialFeeSickDisease(packageId, responseSplit);
			response.data.specialFee += responseSplit.data.specialFee;
			response.data.totalPrice += responseSplit.data.totalPrice;
			response.data.statutoryFee += responseSplit.data.statutoryFee;
			response.data.rebatePrice += responseSplit.data.rebatePrice;
		}

		const pack = this.model.calculation.abCalc.packs.get(excessId);
		setPrices(packageId, response, monthly, pack, this.model, this.cms);

		updateCalcKeys(this.model);
		return Promise.resolve(true);
	}

	private handleSpecialFeeSickDisease(packageId: number, response: any) {
		// Passer ved 10 og 15% uden modifikationer
		if (packageId === this.cms.sickAccidentpackageId) {
			//const sickDiseaseRisk = response.data.productPrices.find( risk => risk.risk_id === 4);
			const sickDiseaseRisk = response.data.quotes[0].selected_risks.find((risk) => risk.risk_id === 4);

			if (!sickDiseaseRisk) {
				return;
			}
			const specialFee = sickDiseaseRisk.price * this.cms.sickDiseasePK1Fee; // is 21%, should be 20%, but includes the 5% pluscustomer
			response.data.specialFee = specialFee;
			// console.log('sickDiseaseRisk.price', sickDiseaseRisk.price, 'this.cms.sickDiseasePK1Fee', this.cms.sickDiseasePK1Fee, 'specialFee', specialFee);
			// console.log('totalPrice', (response.data.totalPrice-response.data.statutoryFee)/12);
			// console.log('specialFee', response.data.specialFee/12);
			// console.log('all', ((response.data.totalPrice-response.data.statutoryFee) + response.data.specialFee) /12);
		}
	}

	private createParamsEpiNew(
		excessId: number,
		packageId: number,
		monthlyPrices: number,
		gender: string,
		dog_count: number,
		dogTypeId: number,
		birthdate: string
	) {
		const params = {
			dog_count,
			gender, // T, H, U -> TÆVE, HAN, Uoplyst
			birthdate, // : '13-01-2019', // dd-MM-yyyy
			race_id: dogTypeId, //'20',
			plus_customer: 'PK0',
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: getRisksForPackage(packageId, this.model),
			ts: new Date().getTime(),
			// optional:''
		};
		if (this.cms.plusCustomerCalculation) {
			params.plus_customer = 'PK1'; // 5%
		}
		// monthlyPrices: `${monthlyPrices}`,
		// excessId: `${excessId}`,  ikke selvrisiko
		//extendedPackage: `${packageId}`,

		return params;
	}

	// private prices: Map<string, Array<string>>;
	// private async testDogPrices(from, to, monthly) {
	//     const packageId = parseInt(this.cms.sickAccidentpackageId);
	//     const promises = [];
	//     console.log('running', from, to);
	//     const dogList = this.cms.dogList.slice(from, to);
	//     dogList.forEach((_dogType) => {
	//         const dogType = _dogType;
	//         const dogTypeId = this.cms.dogListIds[ this.cms.dogList.indexOf(_dogType)];
	//         promises.push(this.abAxiosCalculator.getRetry(
	//                 this.cms.oldCalculatorPrices,
	//                 this.createParamsEpi(-1, packageId, monthly, this.model.dogInfo.gender1, 1, dogTypeId, this.model.dogInfo.birthdate1)
	//             ).then( response => {
	//                 try {
	//                     let arr = this.prices.get(response.data.productPrices[2].price + '');
	//                     if (!arr) {
	//                         arr = [];
	//                         this.prices.set(response.data.productPrices[2].price + '', arr);
	//                     }
	//                     arr.push(dogTypeId + ', ' + dogType)
	//                     console.log(response.data.productPrices[2].price, dogTypeId, dogType);
	//                 } catch(e) {
	//                     console.error('failed', dogTypeId, dogType);
	//                 }
	//         }));
	//     });
	//     return await Promise.all(promises);
	// }

	// private async doCallTest() {
	//     this.prices = new Map();
	//     // 298
	//     const monthly = 1; // yearly = 0, monthly = 1
	//     await this.testDogPrices(0,20, monthly);
	//     await this.testDogPrices(20,40, monthly);
	//     await this.testDogPrices(40,60, monthly);
	//     await this.testDogPrices(60,80, monthly);
	//     await this.testDogPrices(80,100, monthly);
	//     await this.testDogPrices(100,120, monthly);
	//     await this.testDogPrices(120,140, monthly);
	//     await this.testDogPrices(140,160, monthly);
	//     await this.testDogPrices(160,180, monthly);
	//     await this.testDogPrices(180,200, monthly);
	//     await this.testDogPrices(200,220, monthly);
	//     await this.testDogPrices(240,260, monthly);
	//     await this.testDogPrices(260,280, monthly);
	//     await this.testDogPrices(280,298, monthly);
	//     console.log(this.prices);

	// }
}
